import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {AbstractResource, CarrierGroupResource, OrderResource, StockMovementLineResource} from '@resources';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { SessionHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { ICarrierGroup } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { takeUntil } from 'rxjs/operators';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import {HydraHelper} from '@helpers/HydraHelper';

@Component({
  selector: 'app-stock-shooting-form',
  template: require('./stock-shooting-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockMovementLineResource },
  ],
})
export class StockShootingFormComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public carriers: any[] = [];
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public shippingAddressForm: FormGroup;
  public countries = SessionHelper.getApplicationActiveCountries().concat(SessionHelper.getApplicationActiveCountriesWithParent()).map(country => {
    return {
      ...country,
      name: country.translations[this.currentLocale].name
    };
  });

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchCarrier();
    this.initWarehouse();
    this.initAddressForm();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      carrierGroup: [null],
      fromWarehouse: [null],
      file: [null, Validators.required],
    });
  }

  public fetchCarrier(): void {
    this.carrierGroupResource.getMany(
      { pagination: false },
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((carrierGroups: ICarrierGroup[]) => {
        carrierGroups.forEach((carrierGroup: ICarrierGroup) => {
          if (carrierGroup.code === 'enlevement' || carrierGroup.code === 'affretement' || carrierGroup.code === 'ag_transport') {
            this.carriers.push(carrierGroup);
          }
        });
        this.initCarrier();
      })
    ;
  }

  public initCarrier(): void {
    this.form.get('carrierGroup').setValue(this.carriers.find((carrierGroup: ICarrierGroup): boolean => {
      return carrierGroup.code === 'enlevement';
    })['@id']);
  }

  public initWarehouse(): void {
    this.form.get('fromWarehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === 'lsl';
    })['@id']);
  }
  public initAddressForm(): void {
    this.shippingAddressForm = this.buildAddressForm({
      addressLine1: '270 Avenue de l\'Espace',
      postcode: '59118',
      city: 'Wambrechies',
      countryCode: 'FR',
      firstName: 'sweeek',
      lastName: 'sweeek',
      company: 'sweeek',
      phone1: '0672129859',
      email: 'shooting@sweeek.com'
    });
  }

  private buildAddressForm(address: any): FormGroup {
    return this.formBuilder.group({
      firstName: new FormControl(address.firstName, Validators.required),
      lastName: new FormControl(address.lastName, Validators.required),
      company: new FormControl(address.company || null),
      addressLine1: new FormControl(address.addressLine1, Validators.required),
      addressLine2: new FormControl(address.addressLine2 || null),
      addressLine3: new FormControl(address.addressLine3 || null),
      postcode: new FormControl(address.postcode, Validators.required),
      city: new FormControl(address.city, Validators.required),
      province: new FormControl(address.province || null),
      countryCode: new FormControl(address.countryCode, Validators.required),
      phone1: new FormControl(address.phone1, Validators.required),
      email: new FormControl(address.email, [Validators.required, Validators.email]),
    });
  }

  private prepareBody(): any {
    return {
      shippingAddress: this.shippingAddressForm.value,
      billingAddress: this.shippingAddressForm.value,
      locale: this.currentLocale,
      marketplace: 'showroom',
      orderType: 'shooting',
      referralOrderSav: null,
      paymentType: 'free',
      sendPaymentLink: false,
      manuallyAdded: true,
      grcReason: null,
      estimate: false,
      zendeskTicketId: null,
      privateData: false,
    };
  }

  public submit(): void {
    (<OrderResource>this.resource)
      .create(this.prepareBody(), { entryPoint: '/v2/order-manager/manual-order-creation' })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          this.createStockMovement(response.id);
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        }
      );
  }

  public createStockMovement(orderId: number): void {
    const formData: FormData = new FormData();
    const body: any = {
      fromWarehouse: this.form.value.fromWarehouse,
      carrierGroup: this.form.value.carrierGroup,
      order: HydraHelper.buildIri(orderId, 'orders'),
      type: 'shooting'
    };

    if (this.form && this.form.value.file && this.form.value.file.subscriber) {
      this.form.value.file.subscriber.subscribe((fileList: IFileInfo[]) => {
        formData.append('file', <File>fileList[0].rawFile, fileList[0].name);
      });
    }

    this.resource.create(body, { entryPoint: '/v2/stock_movements' })
      .takeUntil(this.destroyed$)
      .subscribe((response: any): void => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        if (response.id) {
          formData.append('businessObject', 'stock-movement-line');
          formData.append('businessObjectId', response.id);
          formData.append('countryCode', SessionHelper.getCountry().code);
          formData.append('locale', SessionHelper.getLocale());

          this.resource.uploadFile(formData, { entryPoint: '/v2/import' })
            .takeUntil(this.destroyed$)
            .subscribe(() => {
              this.state.go('stock-shooting.list');
            })
          ;
        }
      })
    ;
  }
}
