import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICountry } from '@interfaces/ICountry';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { SessionHelper } from '@helpers/session.helper';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { IImage } from '@components/generic/List';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';
import {
  IProductWebsiteMarketplaceImageList, IProductWebsiteMarketplaceTranslationForm
} from '@components/product/interfaces/product-marketplace-form.interface';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { IMarketplace } from '@interfaces';

@Component({
  selector: 'app-product-website-marketplace-upload-form',
  template: require('./product-website-marketplace-upload-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductWebsiteMarketplaceUploadFormComponent extends AbstractPageComponent implements OnInit {

  @Input() model: IProductForm;
  @Input() marketplace: IMarketplace;
  @Input() sparePartContext: boolean = false;

  @ViewChild('translationsTabs') translationsTabs: TabsetComponent;

  private form: FormGroup;
  private currentCountry: ICountry;
  private productMarketplaceImageList: IProductWebsiteMarketplaceImageList[];

  public fileRestrictions: FileRestrictions = { allowedExtensions: ['.jpg', '.jpeg', '.png', '.mp4', '.webm'] };

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);

    this.currentCountry = SessionHelper.getCountry();
  }

  public ngOnInit(): void {
    this.fetch();
    this.form = this.formBuilder.group({
      translations: [],
    });

    this.buildTranslationsForm();
  }

  private fetch(): void {
    this.productMarketplaceImageList = this.model.country.locales.map((locale: string) => {
      return {
        imageList: this.model.masterProduct.productImages.filter((image: any) => image.version === this.model.getPreviewVersion()).map((img: IImage) => {
          return {
            id: img.id,
            webPath: img.webPath,
            thumbnailWebPath: img.thumbnailWebPath,
            watermarkedWebPath: img.baseWebPath,
            position: img.position,
            selected: false,
            additionalContentForm: this.buildForm(img),
          };
        })
      };
    });
  }

  public submit(event?: any): void {
    let files: IFileInfo[] = [];
    this.form.value.translations.forEach((translation: IProductWebsiteMarketplaceTranslationForm) => {
      if (translation.file && translation.file.subscriber) {
        translation.file.subscriber
          .subscribe((fileList: IFileInfo[]) => {
            const filteredFiles = fileList
              .filter((f: IFileInfo) => this.fileRestrictions.allowedExtensions.includes(f.extension))
              .map((filteredFile: IFileInfo) => {
                filteredFile.locale = translation.locale;
                return filteredFile;
              });
            files = [...filteredFiles, ...files];
          });
      }
    });

    const fileLength = files.length;

    if (0 === fileLength) {
      this.snackbar.warn(this.translate('UPLOADER.NO_FILES_TO_UPLOAD'));

      return;
    }

    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        this.create(files, 0, fileLength, event);
      })
    ;
  }

  private create(files: IFileInfo[], index: number, fileLength: number, event?: any): void {
    const additionalContent: any = {
        name: files[index].additionalContentForm.get('name').value,
        featured: files[index].additionalContentForm.get('featured').value,
        titleAttribute: files[index].additionalContentForm.get('titleAttribute').value,
        altAttribute: files[index].additionalContentForm.get('altAttribute').value,
        dimensionType: files[index].additionalContentForm.get('dimensionType').value,
        backgroundType: files[index].additionalContentForm.get('backgroundType').value,
        ambianceType: files[index].additionalContentForm.get('ambianceType').value,
        isVideo: files[index].additionalContentForm.get('isVideo').value,
        version: this.model.getPreviewVersion()
      };

    (<ProductResource>this.resource)
      .createWebsiteImage(
        this.model.masterProduct.id,
        <File>files[index].rawFile,
        additionalContent
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IImage) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        this.productMarketplaceImageList.forEach((imageListObject: {imageList: IImage[]}) => {
          imageListObject.imageList = [...imageListObject.imageList, {
            id: response.id,
            webPath: response.webPath,
            thumbnailWebPath: `${response.thumbnailWebPath}`,
            watermarkedWebPath: `${response.watermarkedWebPath}`,
            position: response.position,
            selected: false,
            additionalContentForm: this.buildForm(response),
          }];
        });

        fileLength--;
        if (0 === fileLength) {
          this.formNotifier.notifyFormSubmitted();

          if (event && event.redirect) {
            this.state.go(this.sparePartContext ? 'spare-part.list' : 'product_new.list');

            return;
          }

          this.state.go(
            this.sparePartContext ? 'spare-part.edit.marketplace' : 'product.edit.marketplace',
            { id: this.state.params.id, marketplaceCode: this.marketplace.code }
          );

          return;
        }

        index++;
        this.create(files, index, fileLength, event);
      })
    ;
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale: string) => {
      return this.formBuilder.group({
        file: [null],
        locale,
      });
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  /**
   * Checks if the model translations exists and are sets for a given locale.
   */
  private hasExistingTranslations(locale: string): boolean {
    return this.model.translations && !!this.model.translations[locale];
  }

  /**
   * Checks if the current country has several translations.
   */
  public hasSeveralTranslations(): boolean {
    return this.model.country.locales.length > 1;
  }

  private select(locale: string, index: number): void {
    if (!this.hasExistingTranslations(locale)) {
      this.dialog.alert(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.TAB.PICTURES.ALERTS.WARNING'))
        .then(() => {
          this.translationsTabs.tabs[(index - 1)].active = true;
        });
    }
  }

  private buildForm(img: IImage): FormGroup {
    return this.formBuilder.group({
      name: [img.name, Validators.minLength(40)],
      titleAttribute: [img.titleAttribute, Validators.minLength(40)],
      altAttribute: [img.altAttribute, Validators.minLength(40)],
      featured: [img.featured],
      dimensionType: [img.dimensionType],
      backgroundType: [img.backgroundType],
      ambianceType: [img.ambianceType],
      isVideo: [img.isVideo],
    });
  }
}
