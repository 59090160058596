import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators';
import { StringHelper } from '../../helpers/StringHelper';
import { AbstractResource, IRequestOptions } from '../../resources/abstract.resource';
import 'rxjs/add/operator/toPromise';
import { IBulkBody } from '@components/super-product/interfaces/form-general.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HydraHelper } from '@helpers/HydraHelper';
import { ProductFormModel } from '@components/product/models/product-form.model';
import { SessionHelper } from '@helpers/session.helper';
import { IExportOptions } from '@interfaces';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import { HttpHelper } from '@helpers';
import { IProduct } from './interfaces/product-light.interface';
import { ProductLightModel } from './models/product-light.model';
import {ProductImportModel} from '@components/product/models/product-import.model';

const exportOptions: IExportOptions[] = [
  {
    entryPoint: `/v2/export_products_stock_by_warehouses.csv`,
    responseType: 'text',
    type: 'text/plain',
    filename: `export_${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
    translationKey: 'PAGE.PRODUCT.LIST.FILTER.EXPORT.PRODUCTS',
    filters: false,
    roles: ['ROLE_WALISOFT_AGENT'],
    name: 'exportProduct',
  },
  {
    entryPoint: `/v2/export_products_stock_by_packages_and_warehouses.csv`,
    responseType: 'text',
    type: 'text/plain',
    filename: `export-sage_${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
    translationKey: 'PAGE.PRODUCT.LIST.FILTER.EXPORT.PACKAGES',
    filters: false,
    roles: ['ROLE_WALISOFT_AGENT'],
    name: 'exportPackages',
  }
];

@Injectable()
@Resource({
  routeName: 'product',
  entryPoint: '/products',
  formModel: ProductFormModel,
  exportOptions,
  importOptions: [
    {
      entryPoint: '/api/import/init',
      importButton: 'PAGE.IMPORT.BUTTON.PRODUCT',
      businessObject: 'product',
      hideInfo: false,
      validationState: 'product.import'
    },
    {
      entryPoint: '/api/import/init',
      importButton: 'PAGE.IMPORT.BUTTON.PRODUCT_PACKS',
      businessObject: 'product_pack',
      hideInfo: false,
      validationState: 'product.pack_import'
    },
    {
      entryPoint: '/api/v2/products/import',
      importButton: 'PAGE.PRODUCT.LIST.FILTER.IMPORT.PRODUCTS',
      businessObject: 'product'
    },
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.PRODUCT.LIST.FILTER.IMPORT.PRODUCT_MARKETPLACES',
      businessObject: 'product-marketplace'
    },
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.PRODUCT.LIST.FILTER.IMPORT.MASTER_PRODUCTS_WAREHOUSES',
      businessObject: 'master-product-warehouse'
    },
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.PRODUCT.LIST.FILTER.IMPORT.PRODUCT_CONTENT',
      businessObject: 'product_content'
    },
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.PRODUCT.LIST.FILTER.DUPLICATE.CONTENT',
      extraData: 'PAGE.PRODUCT.LIST.FILTER.EXTRA_DATA',
      businessObject: 'duplicate_product_content'
    },
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.PRODUCT.LIST.FILTER.IMPORT.MUTUALIZED',
      businessObject: 'product-shared-warehouse',
    },
  ],
  cGetDefaultFilters: () => ({ countryCode: SessionHelper.getCountry().code, hidden: '0', packageType: 'all', currentTab: 0 }),
  createAvailable: false,
  listColumns: [
    { field: 'sku', translationKey: 'SKU' },
  ]
})
export class ProductResource extends AbstractResource {

  protected nullableProperties: string[] = [
    'currentOffer',
    'shippingDate',
    'complementaryDescriptionForMarketplace',
    'detailedDescription',
    'descriptionMarketplace',
    'positivePoint1',
    'positivePoint2',
    'positivePoint3',
    'positivePoint4',
    'searchTerm1',
    'searchTerm2',
    'searchTerm3',
    'searchTerm4',
    'searchTerm5',
    'bulletPoint1',
    'bulletPoint2',
    'bulletPoint3',
    'bulletPoint4',
    'bulletPoint5',
    'name',
    'titleAttribute',
    'altAttribute',
    'afterSalesService'
  ];

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Filters products by country code and params.
   *
   * @deprecated
   */
  public filterByCountryCodeAndSku(countryCode: string, params?: object, options?: object): Promise<Object> {
    return this.getMany(params, {
      ...options,
      entryPoint: `${this.entryPoint}/${countryCode}/sku`,
    })
      .toPromise();
  }

  /**
   * Get products filtered by country code and params.
   */
  public getByCountryCodeAndSku(
    countryCode: string,
    sku?: string,
    params: any = {},
    options?: object,
    isSparePart?: boolean
  ): Observable<Object> {
    if (undefined !== sku) {
      params['marketplace[]'] = sku;
    }
    return this.getMany(params, {
      ...options,
      entryPoint: `${this.entryPoint}/${countryCode}/${isSparePart ? 'spareparts' : 'sku'}`,
    });
  }

  /**
   * Partial updates a bunch of products
   */
  // TODO: remove this function and remove the v2 on next function when finish product list migration
  public batchUpdate(products: any[]): Promise<Object> {
    return this.partialUpdate(undefined, products, {
      entryPoint: `${this.entryPoint}/bulk`,
      dontUseModel: true
    })
      .toPromise();
  }

  public batchUpdateV2(products: any[]): Observable<Object> {
    return this.partialUpdate(undefined, products, {
      entryPoint: `/v2/products/bulk`,
      dontUseModel: true,
      usePatch: true
    });
  }

  /**
   * Create product marketplace images.
   */
  public createPMImage(masterProductId: string, file: File, version: string): Observable<Object> {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('version', version);

    return this.uploadFile(formData, { entryPoint: `${this.entryPoint}/${masterProductId}/marketplaceimages` });
  }

  /**
   * Updates product image.
   */
  public updateImage(masterProductId: string, imageId: string, params: object, options?: object): Observable<Object> {
    return this.partialUpdate(undefined, params, {
      ...options,
      entryPoint: `${this.entryPoint}/${masterProductId}/marketplaceimages/${imageId}`,
    });
  }

  /**
   * Removes a product image.
   */
  public destroyImage(masterProductId: string, imageId: string, options?: object): Observable<Object> {
    return this.remove(undefined, {
      ...options,
      entryPoint: `${this.entryPoint}/${masterProductId}/marketplaceimages/${imageId}`,
    });
  }

  /**
   * Create website marketplace images.
   */
  public createWebsiteImage(masterProductId: string, file: File, additionalContent?: any): Observable<Object> {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('name', additionalContent.name);
    formData.append('titleAttribute', additionalContent.titleAttribute);
    formData.append('altAttribute', additionalContent.altAttribute);
    formData.append('featured', additionalContent.featured);
    formData.append('dimensionType', additionalContent.dimensionType);
    formData.append('backgroundType', additionalContent.backgroundType);
    formData.append('ambianceType', additionalContent.ambianceType);
    formData.append('isVideo', additionalContent.isVideo);
    formData.append('version', additionalContent.version);

    return this.uploadFile(formData, { entryPoint: `/producttranslations/${masterProductId}/productimages` });
  }

  /**
   * Updates website image.
   */
  public partialUpdateWebsiteImage(masterProductId: number, imageId: number, params: object, options?: object): Observable<Object> {
    return this.partialUpdate(undefined, params, {
      ...options,
      entryPoint: StringHelper.replacePatterns(
        '/producttranslations/:masterProductId/productimages/:imageId',
        { ':masterProductId': masterProductId, ':imageId': imageId }
      ),
    });
  }

  /**
   * Removes a website image.
   */
  public destroyWebsiteImage(masterProductId: number, imageId: number, options?: object): Observable<Object> {
    return this.remove(undefined, {
      ...options,
      entryPoint: StringHelper.replacePatterns(
        '/producttranslations/:masterProductId/productimages/:imageId',
        { ':masterProductId': masterProductId, ':imageId': imageId }
      ),
    });
  }

  public filterArrivalsByCountryCodeV2(countryCode: string, options?: object): Observable<Object> {
    return this.cGet({'country': countryCode}, { ...options, entryPoint: `/v2/arrivals/future` });
  }

  /**
   * Duplicates data from a product to another.
   */
  public duplicate(
    toId: number,
    fromId: number,
    openAiTranslation: boolean,
    crossSelling: boolean,
    upSelling: boolean,
    priceToMarketplaces: string[],
    options?: object
  ): Observable<Object> {
    return this.create({
      fromProduct: HydraHelper.buildIri(fromId, 'products'),
      toProduct: HydraHelper.buildIri(toId, 'products'),
      openAiTranslation,
      crossSelling,
      upSelling,
      priceToMarketplaces
    }, {
      ...options,
      entryPoint: '/v2/products/duplicate'
    });
  }

  /**
   * Duplicates new content data from a product to another.
   */
  public duplicateNewContent(toId: number, fromId: number, openAiTranslation: boolean, options?: object): Observable<Object> {
    return this.create({
      fromProduct: HydraHelper.buildIri(fromId, 'products'),
      toProduct: HydraHelper.buildIri(toId, 'products'),
      openAiTranslation
    }, {
      ...options,
      entryPoint: '/v2/products/duplicate_new_website'
    });
  }

  /**
   * Exports product.
   */
  // TODO: remove this function and remove the v2 on next function when finish product list migration
  public export(params?: object, options?: object): Promise<object> {
    return this.create(params, {
      ...options,
      entryPoint: `${this.entryPoint}/export`,
    })
      .toPromise();
  }

  public exportV2(params?: object, options?: object): Observable<object> {
    return this.create(params, {
      ...options,
      entryPoint: `${this.entryPoint}/export`,
    });
  }

  /**
   * Gets packages in a product
   *
   * @param {string} sku can be sku|countryCode or id
   * @param {Object} options
   */
  public getProductPackages(sku: string, options?: object): Observable<Object> {
    return this.getMany(undefined, {
      ...options,
      entryPoint: `${this.entryPoint}/${sku}/packages`,
    });
  }

  /**
   * TODO: Remove this at end of list migration
   */
  public ExportProductsStockByPackagesAndWarehouse(country: string): Observable<void> {
    return this.exportFile(
      { country },
      {
        entryPoint: '/v2/export_products_stock_by_packages_and_warehouses.csv',
        responseType: 'text',
        type: 'text/plain',
      }
    );
  }

  /**
   * TODO: Remove this at end of list migration
   */
  public ExportProductsStockByWarehouse(country: string): Observable<void> {
    return this.exportFile(
      { country },
      {
        entryPoint: '/v2/export_products_stock_by_warehouses.csv',
        responseType: 'text',
        type: 'text/plain',
      }
    );
  }

  /**
   * In super product edition form we can bind product declinations to super product.
   */
  public bindProductDeclinationsToSuperProduct(products: IBulkBody): Observable<Object> {
    return this.partialUpdate(undefined, products, {
      entryPoint: `${this.entryPoint}/bulk`,
      dontUseModel: true,
    });
  }

  public exportFile(params?: any, options?: any): Observable<void> {
    if (options.name && ['exportProduct', 'exportPackages'].includes(options.name)) {
      params = {
        ...params,
        country: SessionHelper.getCountry() && SessionHelper.getCountry().code,
      };
    }

    return super.exportFile(params, options);
  }

  public getProductAndSpareParts(params?: any) {
    params = {
      ...params,
      countryCode: SessionHelper.getCountry().code
    };

    return this.create(params, { entryPoint: '/v2/sparepart/retriever' });
  }

  public getSpareParts(params?: any, options?: any) {
    return this.cGet(params, { ...options, entryPoint: '/v2/spare_parts' });
  }

  public getSparePartsByVintage(id: string, vintage: string, params?: any) {
    return this.cGet(params, { entryPoint: `/v2/master_products/${id}/vintages/${vintage}` });
  }

  public getVintagesFromMasterProductId(masterProductId: string, params?: any): Observable<any> {
    params = {
      ...params,
    };

    return this.cGet(params, { entryPoint: `/v2/master_products/${masterProductId}/vintages` });
  }

  public getVintagePossibilites(search: string, params?: any) {
    params = {
      ...params,
    };
    return this.cGet(params, { entryPoint: `/v2/vintage_master_products/filter/?sku=${search}`, isHydra: false },
      new HttpHeaders({ 'Content-type': 'application/json', 'Accept': 'application/json' }));
  }

  public putVintagesFromMasterProductId(productId: string, vintages: any, params?: any): Observable<any> {
    params = {
      ...params,
    };

    const body = {
      productId: productId,
      vintageProducts: vintages
    };

    return this.create(body, { entryPoint: `/v2/sparepart/vintage/update` });
  }

  public getProducts(params?: any, options: any = {}): Observable<any> {
    return super.cGet(params, { ...{ entryPoint: `/v2/products`, isHydra: true }, ...options });
  }

  public getProductsLight(params?: any, options: any = {}): Observable<any> {
    return super.cGet(params, { ...{ entryPoint: `/v2/products/light`, isHydra: true }, ...options });
  }

  public exists(skus: string[] = [], countryCode: string = '', marketplace: string = ''): Observable<object> {
    const params = {
      'sku[]': skus,
      pagination: false,
      countryCode: countryCode,
      marketplace: marketplace
    };
    const options = {
      dontUseModel: true,
      blocking: false,
      isHydra: true,
      returnHydraMembers: true,
      entryPoint: `/v2/products/light`
    };

    return super.cGet(params, options);
  }

  public createVersion(productId: number): Observable<any> {
    return this.create({ product: productId }, { entryPoint: `/v2/products/version` });
  }

  public getForExternal(id: string, options?: IRequestOptions): Observable<ProductLightModel | object> {
    return this.get(id, {
      ...options,
      model: ProductLightModel,
      formModel: ProductLightModel,
      isHydra: true,
      entryPoint: `/v2/products/${id}/external`,
    });
  }

  public retrieveProductImport(importId: string, options?: IRequestOptions): Observable<any> {
    return this.get(importId, {
      ...options,
      model: ProductImportModel,
      isHydra: true,
      entryPoint: `/import/product/${importId}`,
    });
  }

  public retrieveProductPackImport(importId: string, options?: IRequestOptions): Observable<any> {
    return this.get(importId, {
      ...options,
      model: ProductImportModel,
      isHydra: true,
      entryPoint: `/import/product_pack/${importId}`,
    });
  }

  public submitImportValidation(data: ProductImportModel): Observable<any> {
    return this.update(data.process.processId, data, {
      entryPoint: `/import/submit`,
    });
  }
}
