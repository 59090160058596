import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { AbstractResource, IndicatorResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { IndicatorModel } from '@models';
import { expand, reduce } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'sage-synchronization-indicator',
  template: require('./sage-synchronization-indicator.component.html'),
  styles: [require('./sage-synchronization-indicator.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: IndicatorResource },
  ]
})
export class SageSynchronizationIndicatorComponent extends AbstractPageComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public businessObject: any;
  @Input() public businessObjectId: any;
  @Input() public childBusinessObject: any;
  @Input() public childBusinessObjectIds: any;
  @Input() public reload: number = 0;

  public indicator: 'red' | 'green' | 'orange';
  public canDisplayWidget: boolean = false;

  private defaultBody: { [key: string]: any } = {
    type: 'sageSynchronizationIndicator',
  };
  private colors: {[key: string]: string} = {
    green: '#28a745',
    orange: '#f39c12',
    red: '#e74c3c'
  };
  private currentRequest: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
  }

  ngOnDestroy() {
    this.currentRequest.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('reload')
      && changes.reload.currentValue !== changes.reload.previousValue
      && 0 !== changes.reload.currentValue
    ) {
      this.indicator = null;
      this.canDisplayWidget = true;

      this.fetch();
    }
  }

  private fetch(): void {
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
    }

    // TODO: use Mercure
    this.currentRequest = this.resource.get(this.businessObjectId, {params: {...this.defaultBody, businessObject: this.businessObject}, blocking: false})
      .pipe(
        expand((indicator: IndicatorModel) => {
          this.indicator = indicator.status;

          if (indicator.status === 'orange') {
            this.canDisplayWidget = true;

            return this.resource.get(this.businessObjectId, {params: {...this.defaultBody, businessObject: this.businessObject}, blocking: false});
          } else {
            return Observable.empty();
          }
        }),
        reduce((acc, value) => {
          return value;
        })
      )
      .subscribe((indicator: IndicatorModel) => {
        if ('green' !== indicator.status) {
          return;
        }

        this.currentRequest = this.resource.getMany(
          {
            ...this.defaultBody,
            businessObject: this.childBusinessObject,
            businessObjectIds: this.childBusinessObjectIds
          },
          { blocking: false }
        ).pipe(
          expand((indicators: IndicatorModel[]) => {
            // tslint:disable-next-line:no-shadowed-variable
            const isSynchronizing = indicators.some((indicator: IndicatorModel) => indicator.status === 'orange');
            // tslint:disable-next-line:no-shadowed-variable
            const hasError = indicators.some((indicator: IndicatorModel) => indicator.status === 'red');

            if (isSynchronizing && !hasError) {
              this.canDisplayWidget = true;
              this.indicator = 'orange';

              return this.resource.getMany(
                {
                  ...this.defaultBody,
                  businessObject: this.childBusinessObject,
                  businessObjectIds: this.childBusinessObjectIds
                },
                { blocking: false }
              );
            } else {
              return Observable.empty();
            }
          }),
          reduce((acc, value) => {
            return value;
          })
        )
          .subscribe((indicators: IndicatorModel[]) => {
            // tslint:disable-next-line:no-shadowed-variable
            if (indicators.some((indicator: IndicatorModel) => indicator.status === 'red')) {
              this.indicator = 'red';
            } else {
              this.indicator = 'green';
            }
          })
        ;
      })
    ;
  }

  public matchBackgroundStatus(status: string): string {
    return this.colors[status] || this.colors.red;
  }
}
