export class StockMovementModel {
  public id: number;
  public order: number;
  public status: string;
  public from: string;
  public to: string;
  public received: string;
  public transferred: string;
  public type: string;

  constructor(stockMovement: any) {
    this.id = stockMovement.id;
    this.order = stockMovement.order;
    this.status = stockMovement.status;
    this.from = stockMovement.fromWarehouse ? stockMovement.fromWarehouse.name : null;
    this.to = stockMovement.toWarehouse ? stockMovement.toWarehouse.name : null;
    this.received = stockMovement.received;
    this.transferred = stockMovement.transferred;
    this.type = stockMovement.type;
  }
}
