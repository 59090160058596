import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { AuthService } from '@services';
import { ContainerResource } from '@components/container/resources/container.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { ContainerListFiltersService } from '@components/container/list/container-list-filters.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-container-list',
  template: require('./container-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ContainerResource },
    { provide: AbstractFiltersFieldsService, useClass: ContainerListFiltersService }
  ],
})
export class ContainerListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
