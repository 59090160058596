
export class ImportErrorsModel implements IImportErrors {
  public globalErrors: {message: string}[];
  public lineErrors: IImportErrorLine[];

  constructor(model: IImportErrors) {
    this.globalErrors = model.globalErrors;
    this.lineErrors = model.lineErrors.sort((a, b) => a.line - b.line);
  }

  public hasError(): boolean {
    return 0 !== this.globalErrors.length
      || 0 !== this.lineErrors.length;
  }
}

export class ImportItemFieldModel implements IImportItemField {
  id: string;
  value: string;
  valueOnLoad: string;
  validation: IImportItemFieldValidation;

  constructor(model: IImportItemField) {
    this.id = model.id;
    this.value = model.value;
    this.valueOnLoad = model.value;
    this.validation = model.validation;
    this.validation.validOnLoad = model.validation.valid;
  }
}

export interface IImport {
  status: string;
  process: IImportProcess;
  data: IImportItem[];
  errors: IImportErrors;
}

export interface IImportProcess {
  processId: string;
  processType: string;
  uploadedFiles: {
    accessiblePath: string,
    filename: string,
    extension: string
  }[];
}

export interface IImportItem {
  valid: boolean;
  validOnLoad: boolean;
  fields: IImportItemField[];
  getIdentifier(): string;
}

export interface IImportItemField {
  id: string;
  value: string;
  valueOnLoad: string;
  validation: IImportItemFieldValidation;
}

export interface IImportItemFieldValidation {
  validOnLoad: boolean;
  valid: boolean;
  required: boolean;
  regex: string|null;
  choices: string[]|{text: string, value: string}[]|null;
  multipleChoices: boolean;
}

export interface IImportErrors {
  globalErrors: {message: string}[];
  lineErrors: IImportErrorLine[];
  hasError(): boolean;
}

export interface IImportErrorLine {
  line: number;
  errors: {message: string}[];
}

export interface IImportErrors {
  globalErrors: {message: string}[];
  lineErrors: IImportErrorLine[];
  hasError(): boolean;
}

export interface IImportErrorLine {
  line: number;
  errors: {message: string}[];
}
