import { NgModule } from '@angular/core';
import {GenericFormModule, GenericModule, StockMovementModule} from '@components';
import { CommonModule } from '@angular/common';
import { StockMovementLineResource} from '@resources/stock-movement-line.resource';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {StockShootingListComponent} from '@components/stock-shooting/stock-shooting-list.component';
import {StockShootingResultListComponent} from '@components/stock-shooting/stock-shooting-result-list.component';
import {StockShootingFormComponent} from '@components/stock-shooting/stock-shooting-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UploadModule} from '@progress/kendo-angular-upload';
import { StockMovementResource } from '@resources/stock-movement.resource';
import {StockShootingResultDetailComponent} from '@components/stock-shooting/stock-shooting-result-detail.component';
import {BsDropdownModule} from 'ngx-bootstrap';

@NgModule({
  declarations: [
    StockShootingListComponent,
    StockShootingResultListComponent,
    StockShootingResultDetailComponent,
    StockShootingFormComponent,
  ],
  entryComponents: [
    StockShootingListComponent,
    StockShootingResultListComponent,
    StockShootingResultDetailComponent,
    StockShootingFormComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    DialogModule,
    ReactiveFormsModule,
    UploadModule,
    FormsModule,
    BsDropdownModule,
    StockMovementModule,
  ],
  exports: [
    StockShootingListComponent,
    StockShootingResultListComponent,
    StockShootingResultDetailComponent,
    StockShootingFormComponent,
  ],
  providers: [
    StockMovementResource,
    StockMovementLineResource,
  ]
})
export class StockShootingModule {}
