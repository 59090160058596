import { Component, Inject, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

/**
 * Handle translations form.
 */
@Component({
  selector: 'app-discount-content-form-translations',
  template: require('./discount-content-form-translations.html'),
})
export class DiscountContentFormTranslationsComponent {

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  @Input() isReadOnly: boolean = false;
  constructor(@Inject('TranslationService') public $translate: ng.translate.ITranslateService) {}

  public setColorValue(color: Event, name: string): void {
    this.translationsFA.at(this.tabIndex).get(name).setValue(color);
  }
}
