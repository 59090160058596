import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperProductListComponent } from '@components/super-product/list/super-product-list.component';
import { GenericFormModule, GenericModule } from '@components/generic';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { SuperProductResultListComponent } from '@components/super-product/list/super-product-result-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadModule } from '@progress/kendo-angular-upload';
import { CustomerProductPhotosResource } from '@components/super-product/form/customer-photos/customer-photos.resource';
import { UserGuideResource } from '@components/super-product/form/user-guide/user-guide.resource';
import { CustomerPhotosComponent } from '@components/super-product/form/customer-photos/customer-photos.component';
import { UserGuideComponent } from '@components/super-product/form/user-guide/user-guide.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TabsModule } from 'ngx-bootstrap';
import { DirectivesModule } from '@directives/directives.module';
import { SuperProductFormTranslationsComponent, SuperProductContentWebsiteComponent } from '@components/super-product/form/content-website';
import { PipesModule } from '../../pipes/pipes.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ProductModule } from '@components/product';
import { DuplicateSuperProductComponent } from './duplicate-super-product';
import { TooltipModule } from 'ngx-tooltip';
import { SuperProductFormTranslationsReferenceComponent, SuperProductComponent } from '@components/super-product/form/general';
import {ImportSuperProductComponent} from '@components/super-product/import/import-super-product.component';

@NgModule({
  declarations: [
    SuperProductListComponent,
    SuperProductResultListComponent,
    SuperProductComponent,
    SuperProductContentWebsiteComponent,
    CustomerPhotosComponent,
    UserGuideComponent,
    SuperProductFormTranslationsComponent,
    SuperProductFormTranslationsReferenceComponent,
    DuplicateSuperProductComponent,
    ImportSuperProductComponent,
  ],
  entryComponents: [
    SuperProductListComponent,
    SuperProductComponent,
    SuperProductContentWebsiteComponent,
    CustomerPhotosComponent,
    UserGuideComponent,
    ImportSuperProductComponent,
  ],
    imports: [
        CommonModule,
        GenericModule,
        ReactiveFormsModule,
        GenericFormModule,
        UploadModule,
        DialogsModule,
        TabsModule,
        DirectivesModule,
        PipesModule,
        DropDownsModule,
        ProductModule,
        TooltipModule
    ],
  providers: [
    SuperProductResource,
    CustomerProductPhotosResource,
    UserGuideResource,
  ]
})
export class SuperProductModule {}
