import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import { ISuperProductSku } from '@components/super-product/interfaces/super-product-sku.interface';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { AuthService } from '@services';
import { CategoriesResource } from '@components/categories';
import { TabResource } from '@components/tab';
import { IFormViolation } from '@interfaces/IFormViolation';
import { SnackbarService } from '@components/snackbar';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { ISuperProductsCategories } from '@components/super-product-categories/models/super-product-categories.interface';
import { SuperProductCategoriesResource } from '@components/super-product-categories/super-product-categories.resource';
import { forkJoin } from 'rxjs/observable/forkJoin';
import * as  _ from 'lodash';
import { LoaderService } from '@components/loader';
import { HydraHelper } from '@helpers/HydraHelper';
import { Observable } from 'rxjs/Observable';
import { AbstractResource } from '@resources/abstract.resource';
import {IGrid} from '@components/generic/List';
import {Subscription} from 'rxjs/Subscription';
import {BASE_URL, DATE_SHORT_INTERNATIONAL_FORMAT} from '@constants';
import * as moment from 'moment';

@Component({
  selector: 'app-super-products-form',
  template: require('./super-products-form.component.html'),
  styles: [require('./super-products-form.component.scss')],
  providers: [
    SuperProductCategoriesResource,
    { provide: AbstractResource, useClass: SuperProductCategoriesResource }
  ],
})
export class SuperProductsFormComponent extends AbstractFormComponent implements OnInit {

  @ViewChild('newSuperProduct') public newSuperProductView: ComboBoxComponent;
  public exportSku: any[];
  public importSku: any[];
  public form: FormGroup;
  public newForm: FormGroup;
  public rawSuperProducts: ISuperProductSku[] = [];
  public superProducts: ISuperProductSku[] = [];
  public violations: IFormViolation[]|any = [];
  public superProductCategories: ISuperProductsCategories[];
  public addedSuperProductsCategories: any = [];
  public deletedSuperProductsCategories: any = [];
  public positionChanged: boolean = false;
  public lengowCategories: any = [];
  public toggleAll: boolean = false;
  public selection: any = {};
  public currentLengowCategory?: string = null;
  public columns = [
    { size: 2, title: 'PAGE.CATEGORIES.SUPER_PRODUCTS.TABLE.HEAD.THUMBNAIL', property: 'firstThumbnailPathFromFirstProduct', type: 'thumbnail'},
    { size: 2, title: 'PAGE.CATEGORIES.SUPER_PRODUCTS.TABLE.HEAD.SKU_PARENT', property: 'sku'},
    { size: 2, title: 'PAGE.CATEGORIES.SUPER_PRODUCTS.TABLE.HEAD.REFERENCE', property: 'reference'},
    { size: 2, title: 'PAGE.CATEGORIES.SUPER_PRODUCTS.TABLE.HEAD.LENGOW_SHELF', property: 'lengowShelf'},
  ];
  public getListSuperProductsOrdered: any = [];
  public getListSuperProductsOrderedFiltered: any = [];
  public onlySalable: boolean = false;

  public category: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: SuperProductCategoriesResource,
    public categoriesResource: CategoriesResource,
    private superProductResource: SuperProductResource,
    @Inject('DialogService') private dialog: any,
    private tabResource: TabResource,
    private snackbar: SnackbarService,
    private loaderService: LoaderService
  ) {
    super($translate, authService, null, state);
  }

  async ngOnInit() {
    let filters = {category: this.state.params.id, pagination: false };
    filters = await this.updateFilter(filters);

    this.fetchLengowCategories();

    this.form = new FormGroup({
      superProducts: new FormArray([]),
    });

    this.newForm = new FormGroup({
      superProduct: new FormControl(),
    });

    this.resource.getElastic(filters)
      .takeUntil(this.destroyed$)
      .concatMap((response: ISuperProductsCategories[]) => {
        this.superProductCategories = response;
        this.superProductCategories.forEach((item: any) => {
          this.selection[item.id] = false;
        });
        this.fillSuperProductForms();
        this.form.addControl(
          'featuredSuperProduct',
          new FormControl(this.superProductCategories ? this.getFeaturedSuperProduct() : undefined)
        );
        this.setListSuperProductsOrdered();
        return this.superProductResource.getSkus({locale: SessionHelper.getLocale()});
      })
      .subscribe((response: ISuperProductSku[]) => {
        this.rawSuperProducts = response.map((product: any) => {
          if (product.isHidden === true) {
            product.label = `${product.label} - (${ this.translate('DATA.LABELS.HIDDEN_PRODUCT') })`;
          }

          return product;
        });
        this.filterUnusedSuperProducts();
      });
    this.importSku = [
      {
        entryPoint: '/api/v2/import',
        importButton: 'FILTERS.IMPORT',
        extraData: 'PAGE.PRODUCT.IMPORT.BULK_SKU',
        businessObject: 'super_product_category',
        businessObjectId: this.state.params.id
      },
    ];
    this.exportSku = [
      {
        translationKey: 'FILTERS.EXPORT',
        entryPoint: '/v2/export',
        responseType: 'text/csv',
        type: 'text/csv',
        filename: `export-sku-category-${this.state.params.id}.csv`,
        filters: false,
        roles: ['ROLE_WALISOFT_AGENT'],
        name: 'super_product_category',
        postOptions: {
          exportCode: 'super_product_category',
          formatCode: 'csv',
          dryRun: false,
          deferred: false,
          split: false,
          serializationGroups: [],
          limit: this.state.params.id + '|' + SessionHelper.getLocale(),
          async: false
        }
      }
    ];
  }

  private filterUnusedSuperProducts(): void {
    const filters = {category: this.state.params.id, pagination: false};
    this.resource.getMany(filters)
      .takeUntil(this.destroyed$)
      .subscribe((response: ISuperProductsCategories[]) => {
        const alreadyAdded = response.map((superProduct: ISuperProductsCategories) => +superProduct.superProduct.id);
        let used: any[] = this.getSuperProductList().value.map((superProduct: any) => superProduct.superProductId);
        used = used.concat(alreadyAdded);
        this.superProducts = this.rawSuperProducts.filter((superProduct: ISuperProductSku) => {
          return !used.includes(+superProduct.id);
        });
      });
  }

  public getSuperProductList(): FormArray {
    return this.form.get('superProducts') as FormArray;
  }

  public remove(index: number): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.SUPER_PRODUCTS.CONFIRM.DELETE'))
      .then(() => {
        this.deletedSuperProductsCategories.push(this.getSuperProductList().controls[index].value.id);
        this.getSuperProductList()
          .removeAt(index);
        this.filterUnusedSuperProducts();
        this.form.markAsDirty();
      });
  }

  public add(): void {
    const {superProduct} = this.newForm.value;
    if (undefined !== superProduct) {
      this.getSuperProductList().insert(
        0,

        this.getSuperProductForm({
          superProduct: {
            id: +superProduct.id,
            skuParent: superProduct.label,
            translations: superProduct.translations,
            firstThumbnailPathFromFirstProduct: superProduct.firstThumbnailPathFromFirstProduct,
          }
        },
        true)
      );
      this.setListSuperProductsOrdered();
      this.newForm.reset();
      this.newSuperProductView.reset();
      this.form.markAsDirty();
      this.filterUnusedSuperProducts();
    }
  }

  private fillSuperProductForms(): void {
    if (this.superProductCategories && this.superProductCategories.length) {
      this.superProductCategories.forEach((item: any) => {
        (this.form.get('superProducts') as FormArray).push(this.getSuperProductForm(item));
      });
    }
  }

  public getSuperProductForm(model: any = {}, newEntry: boolean = false): FormGroup {
    const superProductForm = new FormGroup({
      id: new FormControl(model ? model.id : undefined),
      productsUnlisted: new FormControl(model ? model.productsUnlisted : undefined),
      category: new FormControl(this.state.params.id),
      superProductId: new FormControl(model ? model.superProduct.id : undefined),
      sku: new FormControl(model ? model.superProduct.skuParent : undefined),
      reference: new FormControl(
        model && model.superProduct.translations && model.superProduct.translations[SessionHelper.getLocale()]
          ? model.superProduct.translations[SessionHelper.getLocale()].reference
          : undefined
      ),
      lengowShelf: new FormControl(model ? model.lengowShelf : undefined),
      firstThumbnailPathFromFirstProduct: new FormControl(model ? model.superProduct.firstThumbnailPathFromFirstProduct : undefined),
      position: new FormControl(model ? model.position : undefined),
    });

    if (newEntry) {
      this.addedSuperProductsCategories.push(superProductForm.value);
    }

    return superProductForm;
  }

  private getFeaturedSuperProduct(): number {
    const featured = this.superProductCategories.find((superProduct: any) => superProduct.featured);
    return undefined === featured ? undefined : featured.superProduct.id;
  }

  private getPreparedSuperProductCategories(
      superProductsFields: AbstractControl[],
      superProductFeatured: number
  ): ISuperProductsCategories[] {
      const featuredHasChanged: boolean = this.getFeaturedSuperProduct() !== superProductFeatured;

      const formatedSuperProductCategories =  superProductsFields.map((field: AbstractControl, position: number): any => {
          const isOldFeatured = field.value.superProductId === this.getFeaturedSuperProduct();
          const isNewFeatured = field.value.superProductId === superProductFeatured;
          const isAffectedByFeaturedChange: boolean = featuredHasChanged && (isNewFeatured || isOldFeatured);

          if (field.touched || null === field.value.id || this.positionChanged || isAffectedByFeaturedChange) {
              return {
                  featured: field.value.superProductId === superProductFeatured,
                  category: `api/v2/categories/${this.state.params.id}`,
                  id: field.value.id,
                  position,
                  superProduct: `api/v2/super_products/${field.value.superProductId}`,
              };
          }
      });

      return formatedSuperProductCategories.filter((superProductCategory: ISuperProductsCategories) => superProductCategory);
  }

  public checkFormValidity(): void {
    while (this.violations.length) { this.violations.pop(); }

    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }
  }

  public update(returnToList: boolean): void {
    this.checkFormValidity();

    const formatedList = this.getPreparedSuperProductCategories(this.getSuperProductList().controls, this.form.value.featuredSuperProduct);

    const addedArray = formatedList.filter(control =>
      (_.find(this.addedSuperProductsCategories, {id: control.id})) && !this.deletedSuperProductsCategories.includes(control.id));
    const editedArray = formatedList.filter(control =>
      (!_.find(this.addedSuperProductsCategories, {id: control.id})) && !this.deletedSuperProductsCategories.includes(control.id));

    const addedArrayObservable = addedArray.map(cat => this.resource.create(cat));
    const editedArrayObservable = editedArray.map(cat => this.resource.update(`${cat.id}`, cat));
    const deleteArray = this.deletedSuperProductsCategories.map((id: number) => this.resource.remove(`${id}`));

    (addedArray.length > 0 || deleteArray.length > 0)
      ? forkJoin(...addedArrayObservable, ...editedArrayObservable, ...deleteArray)
        .takeUntil(this.destroyed$)
        .subscribe(
          () => {
            this.snackbar.validate('SAVED');
            returnToList
              ? this.state.go(`${this.tabResource.routeName}.list`)
              : this.state.go(
              `${this.categoriesResource.routeName}.edit.superProducts`,
              { id: this.state.params.id },
              { reload: true }
              );
          },
          (reject: any) => {
            this.violations = this.formatViolations(reject);
            this.loaderService.clear();
            setTimeout(() => {
              this.state.go(this.state.current, this.state.params, {reload: true});
            }, 2000);
          }
        )
      : forkJoin(editedArrayObservable)
        .takeUntil(this.destroyed$)
        .subscribe(
          () => {
            returnToList
              ? this.state.go(`${this.tabResource.routeName}.list`)
              : this.state.go(
              `${this.categoriesResource.routeName}.edit.superProducts`,
              { id: this.state.params.id },
              { reload: true }
              );
          },
          (reject: any) => {
            this.violations = this.formatViolations(reject);
            this.loaderService.clear();
            setTimeout(() => {
              this.state.go(this.state.current, this.state.params, {reload: true});
            }, 2000);
          }
        )
      ;
  }

  public cancel(): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.tabResource.routeName}.list`));
  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }

  public delete(id: string): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.DELETE'))
      .then(() => {
        this.categoriesResource.remove(id)
          .takeUntil(this.destroyed$)
          .concatMap(() => this.categoriesResource.remove(id))
          .subscribe(() => this.state.go(`${this.tabResource.routeName}.list`, {reload: true}));
      });
  }

  public fetchLengowCategories(): void {
    this.categoriesResource.cGet(
      { marketplace: MarketplaceHelper.getWebsiteMarketplace().code, pagination: false },
      { dontUseModel: true, blocking: false, isHydra: true, returnHydraMembers: true }
    )
    .takeUntil(this.destroyed$)
    .subscribe((response: any) => {
      this.lengowCategories = response
        .filter((item: any) => item.translations[this.currentLocale])
        .sort((a: any, b: any) => a.translations[this.currentLocale].lengowName.localeCompare(b.translations[this.currentLocale].lengowName))
        .map((item: any) => {
          return {
            id: HydraHelper.buildIri(item, 'categories'),
            name: item.translations[this.currentLocale].lengowName
          };
        })
      ;
    });
  }

    public setLengowCategory(event: any): void {
    this.currentLengowCategory = event.id;
  }

  public saveLengowShelves(): void {
    if (!this.currentLengowCategory) {
      this.snackbar.warn('You should select a lengow category');

      return;
    }

    const observables: Observable<object>[] = [];

    for (const key in this.getListSuperProductsOrdered) {
      if (this.getListSuperProductsOrdered[key].selected) {
        observables.push(this.resource.update(this.getListSuperProductsOrdered[key].id, { lengowShelf: this.currentLengowCategory }));
      }
    }

    if (!observables.length) {
      this.snackbar.warn('You should select at least one super product');

      return;
    }

    forkJoin(observables)
      .takeUntil(this.destroyed$)
      .subscribe(
        () => {
          this.state.go(this.state.current, this.state.params, {reload: true});
        }
      )
    ;
  }

  public selectAll(): void {
    this.toggleAll = !this.toggleAll;

    for (const key of Object.keys(this.selection)) {
      this.selection[key] = this.toggleAll;
    }
  }

  public getListSuperProducts() {
    return this.form.get('superProducts').value.map((superproduct: any) => {
      superproduct.thumbnailPath = superproduct.firstThumbnailPathFromFirstProduct;
      return superproduct;
    });
  }

  public filterSuperProducts() {
    this.getListSuperProductsOrderedFiltered = this.getListSuperProductsOrdered
      .filter((superProduct: any) => superProduct.productsUnlisted !== true);
  }
  public setListSuperProductsOrdered() {
    const isMerchandisingEnabled = this.category ? this.category.defaultSorting.endsWith('_merchandising') : false;

    if (isMerchandisingEnabled) {
      this.getListSuperProductsOrdered = this.getListSuperProducts();
      this.filterSuperProducts();
      return;
    }

    this.getListSuperProductsOrdered = this.getListSuperProducts().sort(function(obj1: any, obj2: any) {
      return obj1.position - obj2.position;
    });
    this.filterSuperProducts();
  }

  public onDelete(imgs: IGrid[]): void {
    this.deleteProducts(imgs, 0, imgs.length);
    this.filterSuperProducts();
  }

  private deleteProducts(imageList: IGrid[], index: number, imagesLength: number): void {
      this.resource.remove(imageList[index].id)
        .takeUntil(this.destroyed$)
        .subscribe(() => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

          imagesLength--;
          if (0 === imagesLength) {
            return;
          }

          index++;
          this.deleteProducts(imageList, index, imagesLength);
        })
      ;
  }

  public onUpdate(): void {
    const superproducts: any = {'superProductCategories': []};
    this.getListSuperProductsOrdered.forEach(function (item: any)  {
      if (item.id === 'placeholder') {
        return;
      }
      const position = item.position ;
      const featured = position === 0 ;
      superproducts['superProductCategories'].push({
        id: item.id,
        featured: featured,
        position: position
      });
    });
    this.filterSuperProducts();

     this.categoriesResource.update(this.state.params.id, superproducts, { entryPoint: `/v2/categories/position/${this.state.params.id}`}).subscribe();
  }

  public getTranslatableString(key: string, translation: string): string {
    if ((key === 'sells' && translation === 'EMERCH.DESCRIPTION.' )
      || ('price' === key  && 'EMERCH.DESCRIPTION.' === translation)
    ) {
      return null;
    }

    return this.$translate.instant(translation + key.toUpperCase());
  }

  private async updateFilter(filters: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.categoriesResource.get(this.state.params.id)
        .subscribe(
          (response) => {
            this.category = response;
            const isMerchandisingEnabled = this.category ? this.category.defaultSorting.endsWith('_merchandising') : false;

            if (isMerchandisingEnabled) {
              filters = { ...filters, ...{ merchandising: 'default', countryCode: this.currentCountryCode } };
            }

            filters = { ...filters, ...{locale: this.category.translations[0].locale}};

            resolve(filters);
          },
          (error: any) => reject(error)
        );
    });
  }

  public exportSkuCsv() {
    this.snackbar.validate(this.translate('ALERTS.EXPORT.EXPORTING'));

    const subscriber: Subscription = this.resource.exportFile({}, this.exportSku[0])
      .subscribe({
        next: () => {
          this.snackbar.validate(this.translate('ALERTS.EXPORT.SUCCESS'));
        },
        error: () => {
          this.snackbar.warn(this.translate('ALERTS.EXPORT.FAILED'));
        },
        complete: () => {
          subscriber.unsubscribe();
        }
      });
  }
}
