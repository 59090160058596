import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import {
  MarketplacesCommissionResource
} from '@components/marketplaces-configure/commission/marketplaces-commission.resource';
import {SessionHelper} from '@helpers';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {MarketplacesCommissionFiltersService} from '@components/marketplaces-configure/commission/services/marketplaces-commission-filters.service';
import {IExportOptions} from '@interfaces';
import {IImportOptions} from '@interfaces/ImportOptions.interface';
import moment = require('moment');

@Component({
  selector: 'app-marketplaces-commission',
  template: require('./marketplaces-commission.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MarketplacesCommissionResource },
    { provide: AbstractFiltersFieldsService, useClass: MarketplacesCommissionFiltersService }
  ],
})
export class MarketplacesCommissionComponent extends AbstractPageComponent {
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  importOptions: IImportOptions[] = [
    {
      entryPoint: '/api/v2/import',
      importButton: 'BUTTON.IMPORT.CSV',
      businessObject: 'catalog-marketplace-commission',
      businessObjectId: this.state.params.id,
    },
  ];
  exportOptions: IExportOptions[] = [{
    entryPoint: '/v2/export',
    responseType: 'text/csv',
    type: 'text/csv',
    filename: `catalog-marketplace-${moment().format('YYYYMMDDHHmmss')}.csv`,
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: false,
    postOptions: {
      exportCode: 'catalog_marketplace',
      formatCode: 'csv_excel',
      dryRun: false,
      deferred: false,
      split: false,
      serializationGroups: [],
      async: false,
      limit: null,
      limitFields: JSON.stringify({
        country: SessionHelper.getCountry().code,
      })
    }
  }];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
