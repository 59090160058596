import {Component} from '@angular/core';
import { FormService } from '@services/form.service';
import {StockMovementResultListComponent} from '@components/stock-movement';

@Component({
  selector: 'app-stock-shooting-result-list',
  template: require('./stock-shooting-result-list.component.html'),
  providers: [
    FormService
  ],
})

export class StockShootingResultListComponent extends StockMovementResultListComponent {}
