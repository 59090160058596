import { Component, Inject, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import {ProductImportModel, ProductImportProductItemModel} from '@components/product/models/product-import.model';
import {SnackbarService} from '@components/snackbar';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-import-product',
  template: require('./import-product.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ImportProductComponent extends AbstractFormComponent {

  public importModel: ProductImportModel;
  public status: string = null;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  retrieveImportModel(): Promise<ProductImportModel> {
    return new Promise<ProductImportModel>((resolve, reject) => {
      let observable: Observable<any>;

      switch (this.state.current.name) {
        case 'product.import':
          observable = this.resource.retrieveProductImport(this.state.params.importId);
          break;
        case 'product.pack_import':
          observable = this.resource.retrieveProductPackImport(this.state.params.importId);
          break;
      }

      observable
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: ProductImportModel) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        )
      ;
    });
  }

  cancelImport() {
    this.state.go('product_new.list', {}, {reload: true});
  }
}
