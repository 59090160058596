import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { SliderItemsModel } from '@components/website-homepage/website-homepage-slider-items/models';
import { SessionHelper } from '@helpers/session.helper';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'websiteHomepage.slider-items',
  entryPoint: '/v2/slider_items',
  translationKey: 'SLIDER_ITEMS',
  model: SliderItemsModel,
  buttonActionMultipleAvailable: true,
})
export class WebsiteHomepageSliderItemsResource extends AbstractResource {

  protected nullableProperties: string[] = ['dateStart', 'dateEnd', 'title', 'productMarketplace'];

  constructor(http: HttpClient) {
    super(http);
  }
}
