export default loginRoutes;

/** @ngInject */
function loginRoutes($stateProvider: any) {

  $stateProvider
    .state('anonymous', {
      abstract: true,
      template: '<ui-view>',
      data: {
        secure: false,
      }
    })
    .state('anonymous.sign-in', {
      url: '/sign-in?code&scope&platform',
      template: '<app-login>',
      data: {
        title: 'PAGE.ANONYMOUS.SIGN-IN.TITLE'
      }
    })
    .state('anonymous.oauth-validate', {
      url: '/oauth/:platform/validate?state&code&scope',
      template: '<app-oauth-validate>',
      data: {
        title: 'PAGE.ANONYMOUS.SIGN-IN.TITLE'
      }
    })
  ;
}
