import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'discounts_included_categories',
  entryPoint: '/v2/discounts_included_categories'
})
export class DiscountIncludedCategoriesResource extends AbstractResource {
  protected nullableProperties: string[] = ['errorMessage'];

  constructor(http: HttpClient) {
    super(http);
  }

  public createDiscountIncludedCategories(body: object): Observable<Object> {
    return this.create(body, {entryPoint: `${this.entryPoint}`});
  }

  public removeDiscountIncludedCategories(id: string): Observable<Object> {
    return this.remove(id, {
      entryPoint:  `${this.entryPoint}/discount/${id}`,
    });
  }
}
