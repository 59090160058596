import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import {Component, Inject, Input, OnInit} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService} from '@services/form.service';
import {CarrierResource} from '@resources';
import {ICarrierGroup} from '@components/carrier';

@Component({
  selector: 'app-carrier-group-list-item',
  template: require('./carrier-group-list-item.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierResource },
    FormService
  ]
})
export class CarrierGroupListItemComponent extends AbstractPageComponent implements OnInit {
  @Input() public row: ICarrierGroup;

  public editionMode: boolean = false;
  public form: FormGroup;

  public increaseTypes = [
    {type: 'EUR'},
    {type: '%'},
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      increase: [this.row.increase ?
          ('%' === this.row.increaseType ?
              ((this.row.increase - 1) * 100).toFixed(2).toString()
              : this.row.increase
          ) : '', Validators.pattern(/^\d*((,|\.)\d+)?$/)
        ],
      increaseType: [this.row.increaseType ? this.row.increaseType : '', null],
      fuelTax: [this.row.fuelTax ? ((this.row.fuelTax - 1) * 100).toFixed(2) : '', Validators.pattern(/^\d*((,|\.)\d+)?$/)],
    });
  }

  getEditUrl(id: number): string {
    return this.state.href('carrier.edit', {id: id});
  }

  getFormattedIncrease(): string {
    return this.row.increase ?
      ('%' === this.row.increaseType ?
        ((this.row.increase - 1) * 100).toFixed(2).toString() + '%'
        : new Intl.NumberFormat('fr-FR', {style: 'currency', currency: this.row.increaseType}).format(this.row.increase))
      : ''
    ;
  }

  getFormattedFuelTax(): string {
    return this.row.fuelTax
      ? ((this.row.fuelTax - 1) * 100).toFixed(2).toString() + '%'
      : '';
  }

  switchToEditionMode(): void {
    this.editionMode = true;
  }

  switchToReadMode(): void {
    this.editionMode = false;
  }

  save(): void {
    const increase = this.form.get('increase').value ? this.form.get('increase').value : '';
    const increaseType = this.form.get('increaseType').value ? this.form.get('increaseType').value : '';
    const fuelTax = this.form.get('fuelTax').value ? this.form.get('fuelTax').value : '';

    this.resource.update(this.row.config.id, {
      increase: '%' === increaseType ? 1 + (parseFloat(increase.toString().replace(',', '.')) / 100) : parseFloat(increase.toString().replace(',', '.')),
      increaseType: increaseType,
      fuelTax: parseFloat(fuelTax.toString().replace(',', '.')) / 100 + 1
    })
      .takeUntil(this.destroyed$)
      .subscribe((data: ICarrierGroup) => {
        this.row.increase = data.increase ? data.increase : null;
        this.row.increaseType = data.increaseType ? data.increaseType : null;
        this.row.fuelTax = data.fuelTax ? data.fuelTax : null;
        this.editionMode = false;
      });
  }
}
