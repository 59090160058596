import {Component} from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import {StockMovementLineResource} from '@resources';
import { FormService } from '@services/form.service';
import {StockMovementResultDetailComponent} from '@components/stock-movement';

@Component({
  selector: 'app-stock-shooting-result-detail',
  template: require('./stock-shooting-result-detail.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockMovementLineResource },
    FormService
  ],
})

export class StockShootingResultDetailComponent extends StockMovementResultDetailComponent {}
