import { Component, Inject, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import {ProductImportModel} from '@components/product/models/product-import.model';
import {SnackbarService} from '@components/snackbar';
import {SuperProductResource} from '@components/super-product/super-product.resource';
import {IImport} from '@components/generic/import-v2/models/import-field.model';

@Component({
  selector: 'app-import-super-product',
  template: require('./import-super-product.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SuperProductResource },
  ],
})
export class ImportSuperProductComponent extends AbstractFormComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  retrieveImportModel(): Promise<IImport> {
    return new Promise<IImport>(async (resolve, reject) => {
      this.resource
        .retrieveSuperProductImport(this.state.params.importId)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: IImport) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  cancelImport() {
    this.state.go('super-product.list', {}, {reload: true});
  }
}
