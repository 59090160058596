import {Component, Inject, Input, OnInit} from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IImportItem, IImportItemField} from '@components/generic/import-v2/models/import-field.model';

@Component({
  selector: 'app-import-v2-fields',
  template: require('./import-v2-fields.component.html'),
})
export class ImportV2FieldsComponent extends AbstractFormComponent implements OnInit {

  @Input()
  public item: IImportItem;
  public form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.item.valid = this.form.valid;
    this.item.fields.forEach((element: IImportItemField, index) => {
      this.item.fields[index].validation.valid = this.form.get(element.id).valid;
      this.form.get(element.id).markAsDirty();
    });
  }

  buildForm(): void {
    const formGroup: any = {};

    this.item.fields.forEach((field: IImportItemField) => {
      const validators = field.validation.required ? [Validators.required] : [];
      let value: string|string[] = field.value;

      if (field.validation.regex) {
        validators.push(Validators.pattern(new RegExp(field.validation.regex.slice(1, -1))));
      }

      if (
        field.validation.choices
        // @ts-ignore
        && !field.validation.choices.map((data: string | {value: string}): string => typeof data === 'string' ? data : data.value).includes(field.value)
      ) {
        value = null;
      }


      if (
        field.validation.choices
        && field.validation.multipleChoices
      ) {
        const serializedChoices: string[] = 'string' === typeof field.validation.choices[0]
          ? (field.validation.choices as string[])
          : (field.validation.choices as {value: string}[]).map((choice: {value: string}) => choice.value);

        value = field.value ? field.value.split(',') : [];
        value = value.filter((choiceValue: string) => serializedChoices.includes(choiceValue));
      }

      formGroup[field.id] = [value, validators];
    });

    this.form = this.formBuilder.group(formGroup);
    this.form.updateValueAndValidity();
  }

  onFieldChange(fieldName: string, index: number): void {
    this.item.valid = this.form.valid;
    this.item.fields[index].validation.valid = this.form.get(fieldName).valid;
    this.item.fields[index].value = this.serializeFormValue(this.form.get(fieldName).value);
  }

  serializeFormValue(value: any): string|null {
    if (!Array.isArray(value)) {
      value = [value];
    }

    value = value.map((item: any): string => typeof item === 'object' && item.value ? item.value : item);

    return value.join(',');
  }
}
