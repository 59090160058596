import { EventEmitter, Inject, OnDestroy, Optional, Output } from '@angular/core';
import { CountryHelper, SessionHelper, TranslationHelper } from '@helpers';
import { IAbstractComponent } from '@components/generic/interfaces/abstract-component.interface';
import { AuthService } from '@services';
import { Subject } from 'rxjs/Subject';
import { IResource } from '@interfaces/IResource';
import { IActions } from '@components/generic/interfaces/actions.interface';

export const ACTIONS: IActions = {
  create: {
    available: false,
    go: null,
  },
  list: {
    available: false,
    go: null,
  },
  update: {
    available: false,
    go: null,
  },
  customDelete: {
    available: false,
  },
  delete: {
    available: false,
  },
  download: {
    available: false,
  },
  archive: {
    available: false,
  },
  buttonMultiple: {
    available: false,
  },
  custom: [{
    available: false,
    go: null,
    icon: '',
    color: '',
    text: ''
  }]
};

/**
 * Offers common features for components.
 */
export abstract class AbstractComponent implements OnDestroy, IAbstractComponent {

  public currentCountryCode: string = SessionHelper.getCountry() ? SessionHelper.getCountry().code : null;
  public currentLocale: string = SessionHelper.getLocale();
  public userSelectedLocale: string = SessionHelper.getSelectedLocale() || this.currentLocale;
  public userLocales: string[] = CountryHelper.getAllLocalesForApplication();
  public currency: string = SessionHelper.getCurrency();
  public shortLocale: string = this.currentLocale ? this.currentLocale.split('_')[0] : '';
  public actions: IActions = ACTIONS;

  protected destroyed$: Subject<boolean> = new Subject();

  @Output() customAction: EventEmitter<any> = new EventEmitter();

  protected constructor(
    @Inject('TranslationService') protected $translate: ng.translate.ITranslateService,
    protected authService: AuthService,
    @Optional() protected resource: IResource,
    @Inject('StateService') public state: ng.ui.IStateService,
  ) {
    if (this.resource) {
      const createRouteName = this.resource.createRouteName || `${this.resource.routeName}.new`;
      const updateRouteName = this.resource.updateRouteName || `${this.resource.routeName}.edit`;
      const listRouteName = this.resource.listRouteName || `${this.resource.routeName}.list`;

      this.actions = {
        create: {
          available: this.isActionAvailable(resource.createAvailable),
          go: this.isActionAvailable(resource.createAvailable) ?
            (params?: {}, options?: {}) => this.state.go(createRouteName, params, options) :
            null,
        },
        list: {
          available: this.isActionAvailable(resource.listAvailable),
          go: this.isActionAvailable(resource.listAvailable) ?
            (params?: {}, options?: {}) => this.state.go(listRouteName, params, options) :
            null,
        },
        update: {
          available: this.isActionAvailable(resource.updateAvailable),
          go: this.isActionAvailable(resource.updateAvailable) ?
            (params?: {}, options?: {}) => this.state.go(updateRouteName, params, options) :
            null,
        },
        customDelete: {
          available: this.isActionAvailable(resource.customDeleteAvailable)
        },
        delete: {
          available: this.isActionAvailable(resource.deleteAvailable)
        },
        download: {
          available: this.isActionAvailable(resource.downloadAvailable)
        },
        archive: {
          available: this.isActionAvailable(resource.archiveAvailable)
        },
        buttonMultiple: {
          available: this.isActionAvailable(resource.buttonActionMultipleAvailable)
        },
        custom: resource.customActionAvailable ? resource.customAction.map((customAction: any) => {
          return {
            available: this.isActionAvailable(resource.customActionAvailable),
            go: customAction.routeName ?
              (row: any) => {
                const myParams: any = {};
                Object.keys(customAction.params).forEach((key) => {
                  myParams[key] = row[customAction.params[key]];
                });
                this.state.go(customAction.routeName, myParams, customAction.options);
              } :
              (row: any) => {
                this.customAction.emit({function: customAction.function, params: row});
              },
            icon: customAction.icon,
            text: customAction.text,
            color: customAction.color,
          };
        }) : [],
      };
    }
  }

  public translate(key: string = '', params: object = {}): string {
    if (null === key || undefined === key) {
      return;
    }

    return this.$translate.instant('' + key.toString(), params);
  }

  public isGranted(roles: string | string[]): boolean {
    return this.authService.isGranted(roles);
  }

  public isActionAvailable(actionAvailable: boolean | string | string[]): boolean {
    if ('boolean' === typeof actionAvailable) {
      return actionAvailable;
    }

    return this.authService.isGranted(actionAvailable);
  }

  public getTranslatableTranslation(translatable: any): any {
    return TranslationHelper.getTranslation(translatable, this.currentLocale);
  }

  public isAdmin(): boolean {
    return this.isGranted(['ROLE_ADMIN']);
  }

  public getTranslatableTranslationField(translatable: any, field: string): any {
    const translation = TranslationHelper.getTranslation(translatable, this.currentLocale);

    return translation ? translation[field] : null;
  }

  public keyExists(key?: string): boolean {
    return key !== this.translate(key);
  }

  public setUserSelectedLocale(locale: string): void {
    this.userSelectedLocale = locale;
    SessionHelper.setCurrentUserSelectedLocale(locale);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
