import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@components/snackbar';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { UploadEvent } from '@progress/kendo-angular-upload';
import { SessionHelper } from '@helpers/session.helper';
import { BASE_URL_API } from '@constants';

@Component({
  selector: 'app-import',
  template: require('./import.component.html'),
  styles: [require('./import.component.scss')],
  providers: [{ provide: AbstractResource, useClass: ProductResource }]
})
export class ImportComponent extends AbstractFormComponent implements OnInit {
  @Input() public entryPoint: string;
  @Input() public importButton: string;
  @Input() public hideInfo: boolean = false;
  @Input() public businessObject?: string;
  @Input() public businessObjectId?: string;
  @Input() public extraData?: string;
  @Input() public validationState?: string;

  @Output() public onImportSuccess: EventEmitter<any> = new EventEmitter();

  private form: FormGroup;
  public modalOpened: boolean = false;
  public uploadId: string;

  @ViewChild('kendoFileUploader') kendoFileUploaderRef: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.createForm();
    this.uploadId = this.businessObject + '_' + Math.random().toString(16).slice(2);
  }

  /**
   * Creates form.
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      'file': ['', Validators.required],
    });
  }

  /**
   * Fires by kendo-upload when one or more files are about to be uploaded.
   */
  private onSubmit(event: UploadEvent): void {
    // Delete country_code when importers will be uniform on api side
    if (`${BASE_URL_API}/v2/import` === this.entryPoint) {
      event.data = {
        countryCode: SessionHelper.getCountry().code,
        locale: SessionHelper.getLocale(),
        businessObject: this.businessObject
      };

      if (undefined !== this.businessObjectId) {
        event.data = {
          countryCode: SessionHelper.getCountry().code,
          locale: SessionHelper.getLocale(),
          businessObject: this.businessObject,
          businessObjectId: this.businessObjectId
        };
      }
    } else {
      event.data = {
        country_code: SessionHelper.getCountry().code,
        locale: SessionHelper.getLocale(),
        businessObject: this.businessObject
      };
    }
  }

  private openUploadInput() {
    const inputElemnt = document.getElementById(this.uploadId).firstElementChild as HTMLElement;
    inputElemnt.click();
  }

  private onSelect() {
    this.dialogService.confirm(
      this.translate('ALERTS.CONFIRM.IMPORT')
    )
      .then(() => {
        this.kendoFileUploaderRef.uploadFiles();
      });
  }

  /**
   * Fires by kendo-upload when an `upload` operation has failed.
   * Launch a snackbar alert
   *
   * @param {event} event
   */
  private errorEventHandler(event: { [keys: string]: any }): void {
    event.response.violations[0].message
      ? this.snackbar.alert(event.response.violations[0].message)
      : this.snackbar.alert(event.response.violations[0]);
  }

  /**
   * Fires by kendo-upload when an `upload` operation is successfully completed.
   * Averts operator when file upload success.
   */
  private successEventHandler(event: { [keys: string]: any }): void {
    event.businessObject = this.businessObject;
    event.validationState = this.validationState;

    if (null !== this.validationState && undefined !== event.response.body.processId) {
      this.state.go(this.validationState, {importId: event.response.body.processId}, {reload: true});
    }

    this.onImportSuccess.emit(event);

    this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
  }

  public getModelUrl(): string {
    return `https://walibuy-import.s3-eu-west-1.amazonaws.com/models/${this.businessObject}.csv`;
  }
}
