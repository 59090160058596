export default superProductRoutes;

/** @ngInject */
function superProductRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'super-product',
    title: '',
    reloadChangeCountry: 'super-product.list',
  };

  $stateProvider
    .state('super-product', {
      url: '/super-products',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SUPER_PRODUCT',
        stateName: 'super-product.list'
      }
    })
    .state('super-product.list', {
      url: `?
        page&
        byPage&
        orderBy&
        direction&
        catalogId&
        id&
        categoryId&
        marketplace
      `,
      views: {
        'page@layout': {
          template: '<app-super-product-list></app-super-product-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPER_PRODUCT.LIST.TITLE',
      })
    })
    .state('super-product.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW', stateName: 'super-product.new' },
      views: {
        'page@layout': { template: '<app-super-product></app-super-product>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPER_PRODUCT.NEW.TITLE',
        tabs: [
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.GENERAL.LABEL',
            state: 'super-product.new',
          },
        ],
      })
    })
    .state('super-product.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'super-product.edit' },
      views: {
        'page@layout': { template: '<app-super-product></app-super-product>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPER_PRODUCT.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.GENERAL.LABEL',
            state: 'super-product.edit',
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.USER_GUIDE.LABEL',
            state: 'super-product.edit.user-guide'
          },
          {
            label: 'PAGE.PRODUCT.EDIT.TAB.CONTENT_WEBSITE.TAB_LABEL',
            state: 'super-product.edit.blocks'
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.PHOTOS_FROM_CUSTOMERS.LABEL',
            state: 'super-product.edit.photos-from-customers'
          },
        ],
      })
    })
    .state('super-product.edit.photos-from-customers', {
      url: '/photos-from-customers',
      breadcrumb: { label: 'BREADCRUMB.SUPER_PRODUCT.PHOTOS_FROM_CUSTOMERS' },
      views: {
        'page@layout': { template: '<app-customer-photos></app-customer-photos>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPER_PRODUCT.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.GENERAL.LABEL',
            state: 'super-product.edit',
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.USER_GUIDE.LABEL',
            state: 'super-product.edit.user-guide'
          },
          {
            label: 'PAGE.PRODUCT.EDIT.TAB.CONTENT_WEBSITE.TAB_LABEL',
            state: 'super-product.edit.blocks'
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.PHOTOS_FROM_CUSTOMERS.LABEL',
            state: 'super-product.edit.photos-from-customers'
          },
        ],
      })
    })
    .state('super-product.edit.user-guide', {
      url: '/user-guide',
      breadcrumb: { label: 'BREADCRUMB.SUPER_PRODUCT.USER_GUIDE' },
      views: {
        'page@layout': { template: '<app-user-guide></app-user-guide>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPER_PRODUCT.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.GENERAL.LABEL',
            state: 'super-product.edit',
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.USER_GUIDE.LABEL',
            state: 'super-product.edit.user-guide'
          },
          {
            label: 'PAGE.PRODUCT.EDIT.TAB.CONTENT_WEBSITE.TAB_LABEL',
            state: 'super-product.edit.blocks'
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.PHOTOS_FROM_CUSTOMERS.LABEL',
            state: 'super-product.edit.photos-from-customers'
          },
        ],
      })
    })
    .state('super-product.edit.blocks', {
      url: '/blocks',
      breadcrumb: { label: 'PAGE.PRODUCT.EDIT.TAB.CONTENT_WEBSITE.TAB_LABEL' },
      views: {
        'page@layout': { template: '<app-super-product-content-website></app-super-product-content-website>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPER_PRODUCT.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.GENERAL.LABEL',
            state: 'super-product.edit',
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.USER_GUIDE.LABEL',
            state: 'super-product.edit.user-guide'
          },
          {
            label: 'PAGE.PRODUCT.EDIT.TAB.CONTENT_WEBSITE.TAB_LABEL',
            state: 'super-product.edit.blocks'
          },
          {
            label: 'PAGE.SUPER_PRODUCT.TAB.PHOTOS_FROM_CUSTOMERS.LABEL',
            state: 'super-product.edit.photos-from-customers'
          },
        ],
      })
    })
    .state('super-product.import', {
      url: '/import/:importId',
      parent: 'super-product.list',
      breadcrumb: { label: 'BREADCRUMB.IMPORT', stateName: 'super-product.import' },
      views: {
        'page@layout': { template: '<app-import-super-product></app-import-super-product>' }
      },
      data: commonStateData
    })
  ;
}
