import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'automatic-discount',
  entryPoint: '/v2/discount_automatics',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
  })
})
export class AutomaticDiscountResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    delete params.date;

    return super.cGet(params, options);
  }
}
