import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { ContainerModel } from '@components/container/models/container.model';
import { Observable } from 'rxjs/Observable';
import { ContainerListModel } from '@components/container/models/container-list.model';

@Injectable()
@Resource({
  routeName: 'container',
  entryPoint: '/v2/containers',
  translationKey: 'CONTAINER',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30
  }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id', sortable: true },
    { field: 'supplierLabel', translationKey: 'SUPPLIER_LABEL', type: 'string'},
    { field: 'reference', translationKey: 'REFERENCE', type: 'string', sortable: true },
    { field: 'containerNumber', translationKey: 'CONTAINER_NUMBER', type: 'string', sortable: true },
    { field: 'date', translationKey: 'DATE', type: 'date', sortable: true },
    { field: 'sageIdentifier', translationKey: 'SAGE_IDENTIFIER', type: 'string', sortable: true }
  ],
  listModel: ContainerListModel,
  formModel: ContainerModel,
  createAvailable: true,
  deleteAvailable: true,
  isHydra: true,
})
export class ContainerResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public addArrivalToContainer(containerId: number, body: object): Observable<Object> {
    return this.update(
      null,
      body,
      { entryPoint: `/v2/containers/${containerId}/arrivals` }
    );
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    params['order[id]'] = params.sort_id;
    params['order[date]'] = params.sort_date;
    params['order[reference]'] = params.sort_reference;
    params['order[containerNumber]'] = params.sort_containerNumber;
    params['order[sageIdentifier]'] = params.sort_sageIdentifier;

    const cleanParams: any = Object.keys(params)
      .filter((key) => !key.startsWith('sort_') && !key.startsWith('exists_'))
      .filter((key) => typeof params[key] !== 'undefined')
      .reduce((obj, key) => {
        return Object.assign(obj, { [key]: params[key] });
      }, {});

      if (0 === Object.keys(cleanParams).filter((key: string) => key.startsWith('order[')).length) {
        cleanParams['order[id]'] = 'desc';
      }

    return super.cGet(cleanParams, options);
  }
}
