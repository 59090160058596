import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { IndicatorModel } from '@models/indicator.model';
import { Observable } from 'rxjs/Observable';
import { BASE_URL_API } from '@constants';


@Injectable()
@Resource({
  entryPoint: '/v2/indicators',
  isHydra: true,
  model: IndicatorModel,
  throwViolations: false,
})
export class IndicatorResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getHistory(businessObjectId: any, businessObject: string, type: string): Observable<object> {
    const options: any = {
      headers: {'X-Throw-Violations': '0'},
      params : {businessObject : businessObject, type: type, businessObjectId: businessObjectId}
    };

    return this.http.get(`${BASE_URL_API}/v2/indicators-historic`, options).map((response: any) => {
      return response['hydra:member'][0];
    });
  }
}
