import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { ContainerFormComponent } from '@components/container/form/container-form.component';
import { ContainerListComponent } from '@components/container/list/container-list.component';
import { ContainerResource } from '@components/container/resources/container.resource';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [
    ContainerFormComponent,
    ContainerListComponent,
  ],
  entryComponents: [
    ContainerFormComponent,
    ContainerListComponent,
  ],
  providers: [
    ContainerResource
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule
  ]
})
export class ContainerModule {}
