import { Component, Inject } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { ProformaInvoiceResource } from '@components/proforma-invoice/resources/proforma-invoice.resource';
import { IImport } from '@components/generic/import-v2/models/import-field.model';

@Component({
  selector: 'proforma-invoice-import',
  template: require('./proforma-invoice-import.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProformaInvoiceResource },
  ],
})
export class ProformaInvoiceImportComponent extends AbstractFormComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  retrieveImportModel(): Promise<IImport> {
    return new Promise<IImport>(async (resolve, reject) => {
      this.resource
        .retrieveProformaInvoiceImport(this.state.params.importId)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: IImport) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  cancelImport() {
    this.state.go('proforma_invoice.list', {}, { reload: true });
  }
}
