import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { Observable } from 'rxjs/Observable';
import { SessionHelper } from '../../helpers/session.helper';
import { OrderManagerListModel } from './models/order-manager-list.model';
import { BASE_URL_API } from '@constants';

@Injectable()
@Resource({
  routeName: 'order_manager',
  entryPoint: '/v2/orders',
  isHydra: true,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    order: 'DESC',
    orderType: 'all',
    'countries[]': getCountriesCode(),
  }),
  listColumns: [
    {
      field: 'id',
      type: 'string',
      translationKey: 'ID',
      displayRawHtml: true
    },
    {
      field: 'orderId',
      type: 'string',
      translationKey: 'ORDER_ID',
      displayRawHtml: true
    },
    {
      field: 'date',
      type: 'string',
      translationKey: 'DATE',
      displayRawHtml: true
    },
    {
      field: 'marketplace',
      type: 'string',
      translationKey: 'MARKETPLACE',
      displayRawHtml: true
    },
    {
      field: 'country',
      type: 'string',
      translationKey: 'COUNTRY',
      displayRawHtml: true
    },
    {
      field: 'shippingInformations',
      type: 'string',
      translationKey: 'SHIPPING_INFORMATIONS',
      displayRawHtml: true
    },
    {
      field: 'carrier',
      type: 'string',
      translationKey: 'CARRIER',
      displayRawHtml: true
    },
    {
      field: 'skus',
      type: 'string',
      translationKey: 'SKUS',
      rowToolTipLabelField: 'references',
      displayRawHtml: true
    },
    {
      field: 'totalPrice',
      type: 'string',
      translationKey: 'TOTAL_PRICE',
      displayRawHtml: true
    },
  ],
  translationKey: 'ORDER_MANAGER',
  deleteAvailable: false,
  updateAvailable: true,
  createAvailable: false,
  listModel: OrderManagerListModel,
})
export class OrderManagerResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    delete params.date;
    delete params.dateExportLogistic;
    delete params.dateExportSage;
    delete params.etd;

    return super.cGet(params, options);
  }

  public getPaymentsRefunds(orderId: string, params?: any): Observable<any> {
    return this.http.get(`${BASE_URL_API}/v2/order-manager/orders/${orderId}/payments-refunds`);
  }

  public getPaymentsRefundMinimals(orderId: string): Observable<any> {
    return this.http.get(`${BASE_URL_API}/v2/order-manager/orders/${orderId}/payments-refunds-minimals`);
  }

  // public getLitigations(orderId: string, params?: any): Observable<any> {
  //   return this.http.get(`${BASE_URL_API}/v2/order-manager/orders/${orderId}/litigation`);
  // }
  public updateManual(orderId: string, body: any): Observable<any> {
    return super.update(orderId, body, { entryPoint: `/v2/order-manager/orders/${orderId}` });
  }
  public updateOrderItem(orderItemId: string, body: any): Observable<any> {
    return super.update(orderItemId, body, {entryPoint: `/v2/order_items/${orderItemId}`});
  }

  public updateOrderItemPackage(orderItemPackageId: string, body: any): Observable<any> {
    return super.update(orderItemPackageId, body, {entryPoint: `/v2/order_item_packages/${orderItemPackageId}`});
  }
  public bulkResetShipment(shipments: string[]): Observable<any> {
    return super.create({ shipments: shipments }, { entryPoint: '/v2/order-manager/bulk/shipments/reset' });
  }

  public updateNumberPackage(type: string, shipment: string): Observable<any> {
    return super.create({ shipment: shipment, type: type }, { entryPoint: `/v2/order-manager/package/update-amount` });
  }

  public bulkShipShipment(shipments: string[]): Observable<any> {
    return super.create({ shipments: shipments }, { entryPoint: '/v2/order-manager/bulk/shipments/ship' });
  }

  public bulkSlipShipment(shipments: string[]): Observable<any> {
    return super.create({ shipments: shipments }, { entryPoint: '/v2/order-manager/bulk/shipments/slip' });
  }

  public bulkShipmentCarrier(shipments: string[], carrier: string): Observable<any> {
    return super.create({ shipments: shipments, carrier: carrier }, { entryPoint: '/v2/order-manager/bulk/shipments/carrier' });
  }

  public getAddressHistory(addressId: string) {
    return this.http.get(`${BASE_URL_API}/v2/audits?businessObject=address&businessObjectId=${addressId}`);
  }
  public getArrivalHistory(arrivalId: string) {
    return this.http.get(`${BASE_URL_API}/v2/audits?businessObject=arrival&businessObjectId=${arrivalId}`);
  }
  public getAuditHistory(orderItemPackageId: string) {
    return this.http.get(`${BASE_URL_API}/v2/audits?businessObject=order-item-package&businessObjectId=${orderItemPackageId}`);
  }

  public getShippingInfos(itemId: string) {
    return this.http.get(`${BASE_URL_API}/v2/order-manager/items/${itemId}/shipping`);
  }

  public getShipmentHistory(itemId: string) {
    return this.http.get(`${BASE_URL_API}/v2/order-manager/items/${itemId}/shipment-history`);
  }
}

function getCountriesCode() {
  return SessionHelper.getCountries().map(country => country.code);
}
