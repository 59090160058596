import { Inject, Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CountryResource, MarketplaceResource } from '@resources';
import {
  ICommercialOperations,
  ICommercialOperationsTranslation
} from '../models/commercial-operations.interface';
import { IFormService } from '../../generic/Form/interfaces/form.service.interface';
import * as moment from 'moment';
import {
  DATE_FULL_FORMAT,
  DATE_SHORT_FORMAT,
  MARKETPLACES_AG,
  INPUT_NUMBER_PATTERN_NODEC
} from '@constants';
import { IFormViolation } from '@interfaces';
import { ColorHelper } from '@helpers/ColorHelper';
import { AbstractService } from '@services/abstract.service';
import { COMMERCIAL_OPERATION_GOOD_DEALS } from '../constants/commercial-operations.constants';

@Injectable()
export class FormService extends AbstractService implements IFormService {

  readonly SORTING_FIELD_AVAILABLE_WITH_DIRECTION: string = 'price';

  public violations: IFormViolation[] = [];

  constructor(
    private marketplaceResource: MarketplaceResource,
    private countryResource: CountryResource,
    @Inject('StateService') private state: ng.ui.IStateService,
  ) {
    super();
  }

  public getForm(model: ICommercialOperations): FormGroup {
    return new FormGroup({
      country: new FormControl(model.country),
      marketplace: new FormControl(model.marketplace),
      marketplaces: new FormControl(model.marketplaces, Validators.required),
      type: new FormControl(model.type, Validators.required),
      priority: new FormControl(model.priority),
      defaultSorting: new FormControl(this.getDefaultSorting(model), Validators.required),
      translations: new FormArray([]),
      startDate: new FormControl((model.startDate && model.startTime) ?
        moment(model.startDate, DATE_SHORT_FORMAT).startOf('day')
          .add(model.startTime.getHours(), 'hours')
          .add(model.startTime.getMinutes(), 'minutes')
          .add(model.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT) :
        model.startDate ?
          moment(model.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_SHORT_FORMAT) :
          null
      ),
      startTime: new FormControl(model.startDate ? new Date(model.startDate) : null),
      endDate: new FormControl((model.endDate && model.endTime) ?
        moment(model.endDate, DATE_SHORT_FORMAT).startOf('day')
          .add(model.endTime.getHours(), 'hours')
          .add(model.endTime.getMinutes(), 'minutes')
          .add(model.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT) :
        model.endDate ?
          moment(model.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_SHORT_FORMAT) :
          null
      ),
      endTime: new FormControl(model.endDate ? new Date(model.endDate) : null),
      fontFlashSaleColor: new FormControl(ColorHelper.convertRGBAToHex8(model.fontFlashSaleColor)),
      bgFlashSaleColor: new FormControl(ColorHelper.convertRGBAToHex8(model.bgFlashSaleColor)),
      archived: new FormControl(model.archived),
      goodDeals: new FormControl(model.goodDeals),
      percentage: new FormControl(model.percentage, [Validators.min(0), Validators.max(100)]),
      minimumAmount: new FormControl(model.minimumAmount, [Validators.min(0)]),
      maximumAmount: new FormControl(model.maximumAmount, [Validators.min(0)]),
      validityPeriod: new FormControl(model.validityPeriod, [Validators.min(0), Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)]),
    });
  }

  /**
   * Get default sorting + direction.
   */
  private getDefaultSorting(model?: any): string {
    if (undefined !== model && undefined !== model.defaultSorting) {
      if (this.SORTING_FIELD_AVAILABLE_WITH_DIRECTION === model.defaultSorting) {
        return [model.defaultSorting, model.defaultDirection || null].join(' ');
      }

      return model.defaultSorting;
    }

    return '';
  }

  public createTranslationForm(translation: ICommercialOperationsTranslation): FormGroup {
    return new FormGroup({
      title: new FormControl(translation.title, Validators.required),
      description: new FormControl(translation.description),
      locale: new FormControl(translation.locale),
      id: new FormControl(translation.id),
    });
  }

  public prepareBody(body: any): ICommercialOperations {
    if (body.defaultSorting.hasOwnProperty('value')) {
      body.defaultSorting = body.defaultSorting.value;
    } else if (body.defaultSorting) {
      body.defaultSorting = body.defaultSorting;
    } else {
      body.defaultSorting = 'manual';
    }

    [body.defaultSorting, body.defaultDirection] = body.defaultSorting.split(' ');

    if (undefined === body.defaultDirection) {
      body.defaultDirection = null;
    }

    body.startDate = body.startDate && body.startTime ?
      moment(body.startDate, DATE_SHORT_FORMAT).startOf('day')
        .add(body.startTime.getHours(), 'hours')
        .add(body.startTime.getMinutes(), 'minutes')
        .add(body.startTime.getSeconds(), 'seconds')
        .format(DATE_FULL_FORMAT) :
      body.startDate ?
        moment(body.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_FULL_FORMAT) :
        null;
    body.startTime = body.startDate ? new Date(body.startDate) : null;
    body.endDate = body.endDate && body.endTime ?
      moment(body.endDate, DATE_SHORT_FORMAT).startOf('day')
        .add(body.endTime.getHours(), 'hours')
        .add(body.endTime.getMinutes(), 'minutes')
        .add(body.endTime.getSeconds(), 'seconds')
        .format(DATE_FULL_FORMAT) :
      body.endDate ?
        moment(body.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_FULL_FORMAT) :
        null;
    body.endTime = body.endDate ? new Date(body.endDate) : null;
    body.marketplace = body.marketplace ? body.marketplace.code : null;
    body.marketplaces = body.marketplaces ? body.marketplaces : [];
    body.translations = this.getPreparedTranslations(body.translations);
    body.type = body.type.value;
    body.fontFlashSaleColor = ColorHelper.convertHex8ToRgba(body.fontFlashSaleColor);
    body.bgFlashSaleColor = ColorHelper.convertHex8ToRgba(body.bgFlashSaleColor);
    body.goodDeals = body.type === COMMERCIAL_OPERATION_GOOD_DEALS.value ? false : body.goodDeals;

    // delete priority and dates fields if the marketplace is not AG
    if (!MARKETPLACES_AG.includes(body.marketplace)) {
      delete body.priority;
    }

    // country is not necessary
    delete body.country;

    return body;
  }

  private getPreparedTranslations(translations: ICommercialOperationsTranslation): ICommercialOperationsTranslation[] {
    return translations.reduce(
      (current: any, { title, description, locale, id }: any) => {
        current[locale] = { title, description, locale, id };

        return current;
      },
      {}
    );
  }
}
