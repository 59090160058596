import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import { Moment } from 'moment';
import { AbstractResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import * as moment from 'moment';

@Component({
  selector: 'app-carrier-capacity-week',
  template: require('./carrier-capacity-week.component.html'),
  styles: [require('./week.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class CarrierCapacityWeekComponent extends AbstractPageComponent implements OnInit {
  @Input() public capacities: {[key: string]: any[]} = {};
  @Input() public year: number;
  @Input() public week: number;
  @Input() public days: Moment[] = [];

  public show: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private carrierCapacityResource: CarrierCapacityResource,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.addCapacityLabel();
    this.show = true;
  }

  public addCapacityLabel(): void {
    Object.keys(this.capacities).forEach((date: string): void => {
      this.capacities[date].forEach((capacity: any): void => {
        const carriers: string[] = [];
        const injections: string[] = [];

        capacity.warehouseCarriers.forEach((wc: any): void => {
          carriers.push(wc.carrier.carrierGroup.code.toUpperCase() + ' / ' + wc.carrier.country.code.toUpperCase());
        });

        capacity.carrierGroupAgencyCapacities.forEach((agency: any): void => {
          injections.push(agency.carrierGroupAgency.agencyCode + ' (m3) : ' + agency.capacity);
        });

        capacity.labels = carriers;
        capacity.injections = injections;
      });
    });
  }

  public getExportDays(capacity: any): Moment[] {
    const exportDays: Moment[] = [];
    const capacityDayOfWeek = Number.isInteger(capacity.dayOfWeek) ? capacity.dayOfWeek : moment(capacity.date).day() - 1;

    capacity.daysOfExport.forEach((dayOfExport: number): void => {
      const day: Moment = moment().day(dayOfExport + 1).year(this.year).week(this.week - (dayOfExport !== 6 && dayOfExport > capacityDayOfWeek ? 1 : 0));
      exportDays.push(day);
    });

    return exportDays;
  }

  public edit(id: number): void {
    this.state.go('carrier.capacity.edit', { id: id });
  }

  public delete(id: string): void {
    this.dialog.confirm(this.translate('PAGE.CARRIER_CAPACITY.CONFIRM_DELETE')).then((): void => {
      this.carrierCapacityResource.remove(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((): void => {
          this.snackbar.validate('SAVED');
          setTimeout((): void => {
            this.state.go(this.state.current, this.state.params, {reload: true});
          }, 150);
        })
      ;
    });
  }

  public isPast(day: Moment): boolean {
    return day.hour(23).minute(59).second(59) < moment();
  }
}
