import { IHydraMember } from '@interfaces/hydra-resource.interface';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants';

export class ContainerListModel {
  id: number;
  reference: string;
  containerNumber: string;
  date: string;
  sageIdentifier?: string;
  supplierLabel?: string;

  constructor(container: IHydraMember) {
    this.id = container.id;
    this.reference = container.reference;
    this.containerNumber = container.containerNumber;
    this.date = container.date ? moment(container.date).format(DATE_SHORT_FORMAT) : null;
    this.sageIdentifier = container.sageIdentifier;
    this.supplierLabel = container.supplier ? container.supplier.label : null;
  }
}
