import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { TransactionsResource } from '../../transactions.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { TransactionsFiltersFormService } from '@components/transactions/refunds/list/services';
import { IExportOptions } from '@interfaces/exportOptions.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-refunds-list',
  template: require('./refunds-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: TransactionsResource },
    { provide: AbstractFiltersFieldsService, useClass: TransactionsFiltersFormService }

  ],
})

export class RefundsListComponent extends AbstractPageComponent {
  public exportOption: IExportOptions[] = [{
    entryPoint: '/v2/export',
    responseType: 'text',
    type: 'text/csv',
    filename: `transaction-refund-${moment().format('YYYYMMDDHHmmss')}.csv`,
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: false,
    postOptions: {
      exportCode: 'transaction_refund',
      formatCode: 'csv_excel',
      dryRun: false,
      deferred: true,
      split: false,
      serializationGroups: ['transaction_export'],
      async: true,
      limit: JSON.stringify({'operation': 'debit', 'archived': false})
    }
  }];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public generateRefundInvoice() {
    this.resource
      .generateRefundInvoices()
      .takeUntil(this.destroyed$)
      .subscribe();
  }

  public updateExportOptions(filters?: any) {
    const params = {
        'archived': false,
        date: filters['date'],
        dateEnd: filters['dateEnd'],
        dateEndField: filters['dateEndField'],
        dateStart: filters['dateStart'],
        dateStartField: filters['dateStartField'],
        hipayPaymentProduct: filters['hipayPaymentProduct'],
        'order.billingAddress.countryCode[]': filters['order.billingAddress.countryCode[]'],
        'order.id': filters['order.id'],
        'order.marketplace[]': filters['order.marketplace[]'],
        'order.orderId': filters['order.orderId'],
        paymentType: filters['paymentType'],
        'providerStatus[]': filters['providerStatus[]'],
        'status[]': filters['status[]'],
        transactionCommercialEntity: filters['transactionCommercialEntity'],
        operation: 'debit',
    };




    this.exportOption[0].postOptions.limit = JSON.stringify(params);
  }
}
