import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import { AbstractResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Moment } from 'moment';
import { SessionHelper } from '@helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IWarehouses } from '@components/warehouses/models';
import { DATE_FULL_FORMAT } from '@constants';

@Component({
  selector: 'app-docked-volume',
  template: require('./docked-volume.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class DockedVolumeComponent extends AbstractPageComponent implements OnInit {
  public warehouse: any;
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public warehouseForm: FormGroup;
  public shippedYesterdayVolumes: any = [];
  public shippedTodayVolumes: any = [];
  public previousDockedVolumes: any = [];
  public currentDockedVolumes: any = [];
  public readyDockedVolumes: any = [];
  public provisionalVolumes: any = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private carrierCapacityResource: CarrierCapacityResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.buildWarehouseForm();
    this.setWarehouse('lsl');
  }

  public buildWarehouseForm(): void {
    this.warehouseForm = this.formBuilder.group({
      warehouse: [null],
    });
  }

  public setWarehouse(code: string): void {
    this.warehouseForm.get('warehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === code;
    })['@id']);

    this.warehouseChange();
  }

  public warehouseChange(): void {
    this.warehouse = this.warehouses.find((item: IWarehouses): boolean => {
      return item['@id'] === this.warehouseForm.value.warehouse;
    });

    this.fetch();
  }

  public fetch(): void {
    const options: any = { entryPoint: '/v2/order_item_packages/volumes', isHydra: true, returnHydraMembers: true };
    const today: Moment = moment().hour(0).minute(0).second(0);
    const yesterday = today.clone().subtract(1, 'days');

    this.carrierCapacityResource.cGet(
      { warehouse: this.warehouse.code, status: 'shipped', shippedFrom: yesterday.format(DATE_FULL_FORMAT), shippedTo: today.format(DATE_FULL_FORMAT) }, options
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        this.shippedYesterdayVolumes = response;
      })
    ;

    this.carrierCapacityResource.cGet({ warehouse: this.warehouse.code, status: 'shipped', shippedFrom: today.format(DATE_FULL_FORMAT) }, options)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        this.shippedTodayVolumes = response;
      })
    ;

    this.carrierCapacityResource.cGet({ warehouse: this.warehouse.code, status: 'preparing', to: today.format(DATE_FULL_FORMAT) }, options)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        this.previousDockedVolumes = response;
      })
    ;

    this.carrierCapacityResource.cGet({ warehouse: this.warehouse.code, status: 'preparing', from: today.format(DATE_FULL_FORMAT) }, options)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        this.currentDockedVolumes = response;
      })
    ;

    this.carrierCapacityResource.cGet({ warehouse: this.warehouse.code, status: 'accepted', ready: 1 }, options)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        this.readyDockedVolumes = response;
      })
    ;

    this.carrierCapacityResource.cGet({ warehouse: this.warehouse.code, status: 'accepted' }, options)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        this.provisionalVolumes = response;
      })
    ;
  }
}
