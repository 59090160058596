import { StockMovementResultDetailComponent } from '@components/stock-movement/stock-movement-result-detail.component';
import { StockMovementFormComponent } from '@components/stock-movement/stock-movement-form.component';
import { StockMovementListComponent } from '@components/stock-movement/stock-movement-list.component';
import { StockMovementResultListComponent } from '@components/stock-movement/stock-movement-result-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { StockMovementLineResource } from '@resources/stock-movement-line.resource';
import { StockMovementResource } from '@resources/stock-movement.resource';
import {GenericFormModule, GenericModule} from '@components';
import {BsDropdownModule} from 'ngx-bootstrap';
import {SharedModule} from '@progress/kendo-angular-dropdowns';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    StockMovementListComponent,
    StockMovementResultListComponent,
    StockMovementFormComponent,
    StockMovementResultDetailComponent
  ],
  entryComponents: [
    StockMovementListComponent,
    StockMovementResultListComponent,
    StockMovementFormComponent,
    StockMovementResultDetailComponent
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    DialogModule,
    BsDropdownModule,
    SharedModule,
    PipesModule
  ],
  exports: [
    StockMovementListComponent,
    StockMovementResultListComponent,
    StockMovementFormComponent,
    StockMovementResultDetailComponent
  ],
  providers: [
    StockMovementResource,
    StockMovementLineResource,
  ]
})
export class StockMovementModule {}
