export default commercialOperationsRoutes;

/** @ngInject */
function commercialOperationsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'commercial-operations',
    title: '',
    reloadChangeCountry: 'commercial-operations.list',
  };

  $stateProvider
    .state('commercial-operations', {
      url: '/commercial-operations',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.COMMERCIAL_OPERATIONS',
        stateName: 'commercial-operations.list'
      }
    })
    .state('commercial-operations.list', {
      url: `?
        page
        &byPage
        &startDate
        &endDate
        &marketplace[]
        &country[]
        &translations.title
        &archived
        &status
        &hasParent
        &id
        &type
        &sort_id
        &sort_translations.title
        &sort_marketplace
        &sort_country
        &sort_type
        &sort_productMarketplaceCount
        &sort_startDate
        &sort_endDate
      `,
      views: {
        'page@layout': {
          template: '<app-commercial-operations-list></app-commercial-operations-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.COMMERCIAL_OPERATIONS.LIST.TITLE',
      })
    })
    .state('commercial-operations.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-commercial-operations></app-commercial-operations>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.COMMERCIAL_OPERATIONS.NEW.TITLE',
        tabs: [
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.GENERAL',
            state: 'commercial-operations.new',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.PRODUCTS',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.SEO',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.BLOCKS',
          },
        ],
      })
    })
    .state('commercial-operations.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-commercial-operations></app-commercial-operations>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.COMMERCIAL_OPERATIONS.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.GENERAL',
            state: 'commercial-operations.edit',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.PRODUCTS',
            state: 'commercial-operations.edit.products',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.SEO',
            state: 'commercial-operations.edit.seo',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.BLOCKS',
            state: 'commercial-operations.edit.blocks',
          },
        ],
      })
    })
    .state('commercial-operations.edit.products', {
      url: '/products',
      breadcrumb: { label: 'BREADCRUMB.PRODUCT' },
      views: {
        'page@layout': { template: '<app-commercial-operations-products></app-commercial-operations-products>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.COMMERCIAL_OPERATIONS.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.GENERAL',
            state: 'commercial-operations.edit',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.PRODUCTS',
            state: 'commercial-operations.edit.products',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.SEO',
            state: 'commercial-operations.edit.seo',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.BLOCKS',
            state: 'commercial-operations.edit.blocks',
          },
        ],
      })
    })
    .state('commercial-operations.edit.seo', {
      url: '/seo',
      breadcrumb: { label: 'BREADCRUMB.SEO' },
      views: {
        'page@layout': { template: '<app-commercial-operations-seo></app-commercial-operations-seo>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.COMMERCIAL_OPERATIONS.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.GENERAL',
            state: 'commercial-operations.edit',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.PRODUCTS',
            state: 'commercial-operations.edit.products',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.SEO',
            state: 'commercial-operations.edit.seo',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.BLOCKS',
            state: 'commercial-operations.edit.blocks',
          },
        ],
      })
    })
    .state('commercial-operations.edit.blocks', {
      url: '/blocks',
      breadcrumb: { label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.BLOCKS' },
      views: {
        'page@layout': { template: '<app-commercial-operations-block-list></app-commercial-operations-block-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.COMMERCIAL_OPERATIONS.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.GENERAL',
            state: 'commercial-operations.edit',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.PRODUCTS',
            state: 'commercial-operations.edit.products',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.SEO',
            state: 'commercial-operations.edit.seo',
          },
          {
            label: 'PAGE.COMMERCIAL_OPERATIONS.TABS.BLOCKS',
            state: 'commercial-operations.edit.blocks',
          },
        ],
      })
    })
  ;
}
