import { Component, Inject, OnInit } from '@angular/core';
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { ICountry, IFormViolation } from '@interfaces';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AttributeResource } from '@resources';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { Observable } from 'rxjs/Observable';
import { AttributeAttributeSetResource } from '@components/attribute-set/attribute-attribute-set.resource';
import {
  IAttributeAttributeSet,
  IAttributeSet,
} from '@components/attribute-set/models/attributes-set.interface';
import { ISuperProduct } from '@models/ISuperProduct';
import { IAttribute } from '@components/attribute/models/attribute.interface';
import { from } from 'rxjs/observable/from';
import { mergeMap } from 'rxjs/operators';
import { AttributeTypeWebsiteResource } from '@components/attribute-set/attribute-type-website.resource';

@Component({
  selector: 'app-attribute-set',
  template: require('./attribute-set.component.html'),
  styles: [require('./attribute-set.component.scss')]
})

export class AttributeSetComponent extends AbstractFormComponent implements OnInit {

  public inCreation: boolean;
  public violations: IFormViolation[] | any = [];
  public form: FormGroup;
  public model: IAttributeSet;
  public country: ICountry = SessionHelper.getCountry();
  public superProducts: ISuperProduct[] = [];
  public superProductsId: string[] = [];
  public attributes: IAttribute[] = [];
  public deletedAttributeAttributeSetIds: string[] = [];
  private attributesActiveForAll = new Map();
  private attributesFilterableForAll = new Map();
  private attributesTypeForAll = new Map();
  public attributeTypes: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AttributeSetResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    private snackbar: SnackbarService,
    private superProductResource: SuperProductResource,
    private attributeResource: AttributeResource,
    private attributeAttributeSetsResource: AttributeAttributeSetResource,
    private attributeTypeWebsiteResource: AttributeTypeWebsiteResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation = !this.state.params.id;

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      attributes: new FormArray([]),
      superProducts: new FormControl(),
    });
    this.getAttributes();
    this.getAttributeTypes();

    if (this.inCreation) {
      this.addAttribute();
      this.superProductResource.getSkus({ locale: this.currentLocale })
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.superProducts = response;
        });
      return;
    }
    this.fetch();
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .catch((error) => {
        throw new Error(error);
      })
      .switchMap((response: IAttributeSet) => {
        this.model = response;
        this.form.get('name').setValue(this.model.name);
        this.formatAttributesForm();
        return this.superProductResource.getSkus({ locale: this.currentLocale })
          .catch((error) => {
            throw new Error(error);
          });
      }
      )
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: ISuperProduct[]) => {
          this.superProducts = response;
          this.formatSuperProductsForm();
        },
        () => {
          this.snackbar.alert(this.translate('ALERTS.ERROR.API'));
        })
      ;
  }

  private deleteItems(ids: string[]): Observable<any> {
    return from(ids).pipe(
      mergeMap(id => this.attributeAttributeSetsResource.remove(id).catch(() => Observable.of(id)))
    );
  }

  private formatAttributesForm(): void {
    const attributeAttributeSet: IAttributeAttributeSet[] = this.model.attributeAttributeSets;

    attributeAttributeSet.forEach((attribute: IAttributeAttributeSet) => {
      this.addAttribute(attribute);
    });
  }

  private formatSuperProductsForm(): void {
    this.model.superProducts.forEach((superProduct: ISuperProduct) => {
      if (superProduct.hasOwnProperty('id')) {
          this.superProductsId.push(superProduct.id.toString());
      }
    });

    this.form.get('superProducts')
      .setValue(this.superProducts.filter((superProduct: ISuperProduct) =>
        superProduct.hasOwnProperty('id') && superProduct.id !== undefined && this.superProductsId.includes(superProduct.id.toString())
      ));
  }

  private submit(redirectToList: boolean): void {
    if (this.inCreation) {
      this.resource.create(this.prepareQuery())
        .takeUntil(this.destroyed$)
        .subscribe((response: IAttributeSet) => {
          redirectToList
            ? this.actions.list.go({}, { reload: true, notify: true })
            : this.actions.update.go({ id: response.id }, { reload: true, notify: true })
            ;
        });
      return;
    }

    const requestsQueue = this.deletedAttributeAttributeSetIds.length > 0
      ? this.deleteItems(this.deletedAttributeAttributeSetIds)
        .switchMap((response: any) => {
          if (response !== null) {
            const arrayControls = this.form.get('attributes') as FormArray;
            const formAttribute = arrayControls.controls.find(control => control.get('id').value === response);
            const attribute = this.model.attributeAttributeSets.find(attr => attr.id === response);

            if (!formAttribute) {
              this.addAttribute(attribute);
              this.snackbar.alert(
                this.translate(
                  'PAGE.ATTRIBUTE_SETS.FORM.ALERTS.DELETE.ERROR.GLOBAL',
                  { name: attribute.attribute.translations[this.currentLocale].name }
                )
              );
            }
          }
          return this.resource.partialUpdate(this.state.params.id, this.prepareQuery());
        })
      : this.resource.partialUpdate(this.state.params.id, this.prepareQuery());

    requestsQueue
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: IAttributeSet) => {
          redirectToList
            ? this.actions.list.go({}, { reload: true, notify: true })
            : this.actions.update.go({ id: response.id }, { reload: true, notify: true })
            ;
        },
        (reject) => {
          reject.violations.forEach((violation: IFormViolation) => {
            this.snackbar.alert(violation.message);
          });
        }
      );
  }

  private prepareQuery(): IAttributeSet {
    const arrayControls = this.form.get('attributes') as FormArray;

    const body: any = {
      attributeAttributeSets: [],
      name: '',
      superProducts: [],
    };

    arrayControls.controls.forEach((control, index) => {
      if (control.get('attribute').value && control.get('attribute').value.id) {
        body.attributeAttributeSets.push({
          attribute: control.get('attribute').value.id,
          position: index,
          required: control.get('required').value,
          type: control.get('type').value.value,
          active: control.get('active').value,
          filterable: control.get('filterable').value
        });
      }
    });

    if (this.form.get('superProducts').value && this.form.get('superProducts').value.length > 0) {
      this.form.get('superProducts').value.forEach((superProduct: any) => {
        if (superProduct.hasOwnProperty('id') && this.form.get('superProducts').value) {
          body.superProducts.push(superProduct.id);
        }
      });
    }
    body.name = this.form.get('name').value;

    return body;
  }

  private addAttribute(model?: IAttributeAttributeSet, error: boolean = false): void {
    const arrayControls = this.form.get('attributes') as FormArray;

    arrayControls.push(new FormGroup({
      id: new FormControl(model ? model.id : null),
      attribute: new FormControl(model ? model.attribute : null),
      required: new FormControl(model ? model.required : null),
      filterable: new FormControl(model ? model.filterable : null),
      active: new FormControl(model ? model.active : null),
      type: new FormControl(model ? (undefined !== model.type
        ? this.attributeTypes.find((attributeType: any) => attributeType.value === model.type)
        : this.attributeTypes[0]) : null),
      position: new FormControl(model ? model.position : arrayControls.length + 1),
      error: new FormControl(error),
    }));
  }

  private getAttributes(): void {
    this.attributeResource.getMany()
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        response.forEach((attribute) => {
          attribute.name = attribute.translations[this.currentLocale].name;
        });
        this.attributes = response;
      }
      );
  }

  private getAttributeTypes(): void {
    this.attributeTypes.push({name: '', value: null});

    this.attributeTypeWebsiteResource.cGet({}, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        response.forEach((type) => {
          this.attributeTypes.push({name: type, value: type});
        });
      });
  }

  private getAttributeForm(index: number): FormGroup {
    return (this.form.get('attributes') as FormArray).controls[index] as FormGroup;
  }

  private deleteAttribute(index: number): void {
    const attributesControl = (this.form.get('attributes') as FormArray).controls;
    const id = attributesControl[index].get('id').value;
    this.deletedAttributeAttributeSetIds.push(id);

    for (let i = 0; i < attributesControl.length; i++) {
      if (i === index) {
        (this.form.get('attributes') as FormArray).controls.splice(i, 1);
      }
    }
  }

  private cancel(): void {
    this.dialogService.confirm(this.translate('PAGE.ATTRIBUTE_SETS.CONFIRM.BACK_TO_LIST'))
      .then(() => this.actions.list.go());
  }

  private delete(): void {
    this.dialogService.confirm(this.translate('PAGE.ATTRIBUTE_SETS.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(this.state.params.id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.actions.list.go({ reload: true, notify: true }));
      });
  }

  private drop(droppedData: any, dropZone: AbstractControl): void {
    const arrayControls: FormArray = this.form.get('attributes') as FormArray;
    const dropped: AbstractControl = arrayControls.controls
      .find(control => droppedData.id === control.get('attribute').value.id);

    if (dropZone.get('attribute').value !== dropped.value) {
      const newIndex: number = arrayControls.controls.indexOf(dropZone);
      const oldIndex: number = arrayControls.controls.indexOf(dropped);

      if (newIndex >= arrayControls.controls.length) {
        let k: number = newIndex - arrayControls.controls.length + 1;
        while (k--) {
          arrayControls.controls.push(undefined);
        }
      }
      arrayControls.controls.splice(newIndex, 0, arrayControls.controls.splice(oldIndex, 1)[0]);
    }
  }
}
