import { IAttribute, IAttributeTranslation, IAttributeValue } from '@components/attribute/models/attribute.interface';

export class AttributeModel implements IAttribute {
  public id: number;
  public sortCode: string;
  public attributeValues: any[];
  public translations: { [key: string]: IAttributeTranslation };
  public attributeFamilies: any[];
  public familyActive: boolean;

  constructor(attribute: IAttribute) {
    this.id = attribute.id;
    this.sortCode = attribute.sortCode;
    this.translations = attribute.translations;
    this.attributeFamilies = attribute.attributeFamilies || [];
    this.attributeValues = [];
    this.familyActive = attribute.familyActive;

    if (attribute.attributeFamilies) {
      for (const attributeFamily of attribute.attributeFamilies) {
        for (const attributeValue of attributeFamily.attributeValues) {
          this.attributeValues.push([attributeValue]);
        }
      }
    }
  }
}
