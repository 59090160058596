import { Component, Input, Inject } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-automatic-discount-form-translations',
  template: require('./automatic-discount-form-translations.component.html'),
})
export class AutomaticDiscountFormTranslationsComponent {

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  @Input() isReadOnly: boolean = false;

  constructor(@Inject('TranslationService') public $translate: ng.translate.ITranslateService) {}

  public setColorValue(color: Event, name: string): void {
    this.translationsFA.at(this.tabIndex).get(name).setValue(color);
  }
}
