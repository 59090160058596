
import {
  IAttributeSet,
  IAttributeAttributeSet,
} from '@components/attribute-set/models/attributes-set.interface';
import { ISuperProduct } from '@models/ISuperProduct';

export class AttributesSetModel implements IAttributeSet {
  public id: number;
  public name: string;
  public attributeAttributeSets: IAttributeAttributeSet[];
  public superProducts: ISuperProduct[];

  constructor(attributeSet: IAttributeSet) {
    this.id = attributeSet.id;
    this.name = attributeSet.name;
    this.attributeAttributeSets = attributeSet.attributeAttributeSets;
    this.superProducts = attributeSet.superProducts;
  }
}
