import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { SupplierAddressModel } from '@components/supplier/models/supplier-address.model';
import { SupplierCommercialEntityModel } from '@components/supplier/models/supplier-commercial-entity.model';

export class SupplierModel {
  '@id': string;
  id: number;
  label: string;
  transportType: 'boat' | 'truck';
  address: SupplierAddressModel;
  supplierCommercialEntities: SupplierCommercialEntityModel[];

  constructor(object: IHydraMember) {
    this['@id'] = object['@id'];
    this.id = object.id;
    this.label = object.label;
    this.transportType = object.transportType;
    this.address = new SupplierAddressModel(object.address);
    this.supplierCommercialEntities = object.supplierCommercialEntities.map((supplierCommercialEntity: IHydraMember) =>
      new SupplierCommercialEntityModel(supplierCommercialEntity)
    );
  }
}
