export const BASE_URL = process.env.BASE_URL;
export const BASE_URL_API = process.env.BASE_URL_API;
export const BASE_URL_INVOICE_ORDER = process.env.BASE_URL_INVOICE_ORDER;
export const BASE_URL_INVOICE_ORDER_ITEM = process.env.BASE_URL_INVOICE_ORDER_ITEM;
export const HTML_5_MODE = process.env.HTML_5_MODE;
export const RABBIT_MQ_USER = process.env.RABBIT_MQ_USER;
export const RABBIT_MQ_PW = process.env.RABBIT_MQ_PW;
export const BASE_URL_COMMERCIAL_OPERATION = process.env.BASE_URL_COMMERCIAL_OPERATION;

export const OAUTH_GOOGLE_CLIENT_ID = process.env.OAUTH_GOOGLE_CLIENT_ID;
