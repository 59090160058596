import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService, FormNotifierService } from '@services';
import { FormBuilder, FormGroup} from '@angular/forms';
import { IFormViolation } from '@interfaces';
import { AbstractResource } from '@resources';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFlag } from '@components/commercial-operations/models/flag.interface';
import { ColorHelper } from '@helpers';
import {
  CommercialOperationBlockInsetResource
} from '@components/commercial-operations/resources/commercial-operation-block-inset.resource';
import { takeUntil } from 'rxjs/operators';
import { CommercialOperationsResource } from '@components/commercial-operations/resources';
import {HydraHelper} from '@helpers/HydraHelper';

@Component({
  selector: 'app-form-flag',
  template: require('./form-flag.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CommercialOperationBlockInsetResource },
  ],
})
export class FormFlagComponent extends AbstractFormComponent implements OnInit {
  public form: FormGroup;
  public formGroup: FormGroup;
  public violations: IFormViolation[] | any = [];
  public inCreation: boolean = false;
  public model: IFlag;

  @Input() public locale: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    public formNotifier: FormNotifierService,
    public resource: AbstractResource,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    private commercialOperationsResource: CommercialOperationsResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetch();
  }
  buildForm(): void {
    this.form = this.formBuilder.group({
      id: [this.model ? this.model.id : null],
      priority: [this.model ? this.model.priority : 1],
      title: [this.model ? this.model.title : null],
      mobileTitle: [this.model ? this.model.mobileTitle : null],
      link: [this.model ? this.model.link : null],
      color: [this.model ? ColorHelper.convertRGBAToHex8(this.model.color) : null],
      backgroundColor: [this.model ? ColorHelper.convertRGBAToHex8(this.model.backgroundColor) : null],
      bold: [this.model ? this.model.bold : false],
      dynamic: [this.model ? this.model.dynamic : false],
      italic: [this.model ? this.model.italic : false],
      underline: [this.model ? this.model.underline : false]
    });
  }

  fetch(): void {
    this.commercialOperationsResource.get(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.form.patchValue({ priority: response.priority });
      })
    ;

    this.resource.getCommercialOperationBlockInset(this.state.params.id, this.locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (undefined === response['hydra:member'][0]) {
          this.inCreation = true;
          return;
        }

        this.form.patchValue(response['hydra:member'][0]);
      })
    ;
  }

  public setColorValue(color: Event, name: string): void {
    this.form.get(name).setValue(color);
    this.form.markAsDirty();
  }

  public prepareBody(formValue: IFlag): IFlag {
    return {
      id: formValue.id,
      priority: formValue.priority,
      title: formValue.title,
      mobileTitle: formValue.mobileTitle,
      link: formValue.link,
      color: formValue.color,
      backgroundColor: formValue.backgroundColor,
      bold: formValue.bold,
      dynamic: formValue.dynamic,
      italic: formValue.italic,
      underline: formValue.underline,
      locale: this.locale,
      commercialOperation: HydraHelper.buildIri(this.state.params.id, 'commercial_operations'),
    };
  }

  public submit(): void {
    if (!this.form.dirty) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));

      return;
    }

    const body: IFlag = this.prepareBody(this.form.value);

    this.commercialOperationsResource.partialUpdate(this.state.params.id, { priority: body.priority })
      .takeUntil(this.destroyed$)
      .subscribe();

    if (this.inCreation) {
      this.resource.create(body)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.state.go(this.state.current, this.state.params, { reload: true });
          });
    } else {
      this.resource.update(body.id, body)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            if (!this.inCreation) {
              this.formNotifier.notifyFormSubmitted();
            }
          }
        );
    }
  }

  public delete(): void {
    this.resource.remove(this.form.value.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }
}
