import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractResource, IRequestOptions } from '@resources';
import { ProformaInvoiceModel } from '@components/proforma-invoice/models/proforma-invoice.model';
import { ProformaInvoiceListModel } from '@components/proforma-invoice/models/proforma-invoice-list.model';
import { Observable } from 'rxjs/Observable';
import { ContainerModel } from '@components/container/models/container.model';
import { ProformaInvoiceImportModel } from '@components/proforma-invoice/models/proforma-invoice-import.model';

@Injectable()
@Resource({
  routeName: 'proforma_invoice',
  entryPoint: '/v2/proforma_invoices',
  translationKey: 'PROFORMA_INVOICE',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order[id]': 'DESC',
  }),
  listColumns: [
    { field: 'identifier', translationKey: 'IDENTIFIER', type: 'string' },
    { field: 'date', translationKey: 'DATE', type: 'date' },
    { field: 'supplierLabel', translationKey: 'SUPPLIER_LABEL', type: 'string' }
  ],
  listModel: ProformaInvoiceListModel,
  formModel: ProformaInvoiceModel,
  createAvailable: true,
  deleteAvailable: true,
  isHydra: true,
  customActionAvailable: true,
  customAction: [
    {
      function: 'exportProformaInvoice',
      text: 'PI',
      color: 'btn-primary',
    }
  ],
  importOptions: [
    {
      entryPoint: '/api/import/init',
      importButton: 'PAGE.PROFORMA_INVOICE.LIST.IMPORT.PROFORMA_INVOICES',
      businessObject: 'proforma_invoice',
      hideInfo: false,
      validationState: 'proforma_invoice.import'
    }
  ]
})
export class ProformaInvoiceResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  cGet(params: object | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    const parameters: {[key: string]: any} = params;

    if (parameters && parameters.hasOwnProperty('startDate')) {
      Object.assign(parameters, { 'createdAt[after]': parameters['startDate'] });
      delete parameters['startDate'];
      delete parameters['createdAt'];
    }

    if (parameters && parameters.hasOwnProperty('endDate')) {
      Object.assign(parameters, { 'createdAt[before]': parameters['endDate'] });
      delete parameters['endDate'];
      delete parameters['createdAt'];
    }

    return super.cGet(parameters, options, headers);
  }

  downloadDocument(id: number): Observable<Object> {
    const url = this.getPath(`${this.entryPoint}/${id}/document`);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    });
  }

  getAvailableForContainer(container: ContainerModel): Observable<Object> {
    const parameters: {[key: string]: any} = {
      'pagination': false,
      'commercialEntity': container.commercialEntity.id,
      'warehouse': container.warehouse.id,
      'quantityAvailable': 1
    };

    if (container.supplier) {
      parameters['supplier'] = container.supplier.id;
    }

    return super.cGet(parameters, { listModel: ProformaInvoiceModel, isHydra: true, returnHydraMembers: true });
  }

  retrieveProformaInvoiceImport(importId: string, options?: IRequestOptions): Observable<any> {
    return this.get(importId, {
      ...options,
      model: ProformaInvoiceImportModel,
      isHydra: true,
      entryPoint: `/import/proforma_invoice/${importId}`,
    });
  }
}
