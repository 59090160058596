import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { ISellingProduct } from '@models/ISellingProduct';
import { CrossSellingProductResource } from '@resources/cross-selling-product.resource';
import { UpSellingProductResource } from '@resources/up-selling-product.resource';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import { IProductForm } from '@components/product/interfaces/product-form.interface';

@Component({
  selector: 'app-tab-linked-products-form',
  template: require('./tab-linked-products.component.html')
})
export class TabLinkedProductsComponent extends AbstractComponent {

  @Input() public model: IProductForm;

  public hasUpdates: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private crossSellingResource: CrossSellingProductResource,
    private upSellingResource: UpSellingProductResource,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, null, state);
  }

  private static formatSellings(sellings: ISellingProduct[]): void {
    sellings.forEach((selling: ISellingProduct, index: number) => {
      selling.position = index + 1;
    });
  }

  /**
   * Submits form.
   */
  public submitForm(redirection: string): void {
    TabLinkedProductsComponent.formatSellings(this.model.crossSellingProducts);
    TabLinkedProductsComponent.formatSellings(this.model.upSellingProducts);

    let newCrossSellings: any = [];
    let patchCrossSellings: any = [];
    let deleteCrossSellings: any = [];

    const originalCrossSellingIds = this.model.originalCrossSellingProducts.map((crossSellingProduct: any) => {
      return crossSellingProduct.crossSelling.id;
    });

    const crossSellingIds = this.model.crossSellingProducts.map((crossSellingProduct: any) => {
      return crossSellingProduct.crossSelling.id;
    });

    if (
      JSON.stringify(originalCrossSellingIds) !== JSON.stringify(crossSellingIds) ||
      this.hasUpdates
    ) {
      newCrossSellings = this.model.crossSellingProducts.filter((crossSellingProduct: any) => {
        return -1 === originalCrossSellingIds.indexOf(crossSellingProduct.crossSelling.id);
      });

      patchCrossSellings = this.model.crossSellingProducts.filter((crossSellingProduct: any) => {
        return -1 !== originalCrossSellingIds.indexOf(crossSellingProduct.crossSelling.id);
      });

      deleteCrossSellings = this.model.originalCrossSellingProducts.filter((crossSellingProduct: any) => {
        return -1 === crossSellingIds.indexOf(crossSellingProduct.crossSelling.id);
      });
    }

    let newUpSellings: any = [];
    let patchUpSellings: any = [];
    let deleteUpSellings: any = [];

    const originalUpSellingIds = this.model.originalUpSellingProducts.map((upSellingProduct: any) => {
      return upSellingProduct.upSelling.id;
    });

    const upSellingIds = this.model.upSellingProducts.map((upSellingProduct: any) => {
      return upSellingProduct.upSelling.id;
    });

    if (JSON.stringify(originalUpSellingIds) !== JSON.stringify(upSellingIds)) {
      newUpSellings = this.model.upSellingProducts.filter((upSellingProduct: any) => {
        return -1 === originalUpSellingIds.indexOf(upSellingProduct.upSelling.id);
      });

      patchUpSellings = this.model.upSellingProducts.filter((upSellingProduct: any) => {
        return -1 !== originalUpSellingIds.indexOf(upSellingProduct.upSelling.id);
      });

      deleteUpSellings = this.model.originalUpSellingProducts.filter((upSellingProduct: any) => {
        return -1 === upSellingIds.indexOf(upSellingProduct.upSelling.id);
      });
    }

    const requests: any = [];

    deleteCrossSellings.forEach((crossSellingProduct: any) => {
      const id = `product=${this.model.id};crossSelling=${crossSellingProduct.crossSelling.id}`;

      requests.push(this.crossSellingResource.remove(id));
    });

    patchCrossSellings.forEach((crossSellingProduct: any) => {
      const id = `product=${this.model.id};crossSelling=${crossSellingProduct.crossSelling.id}`;

      requests.push(this.crossSellingResource.update(id, {'position': crossSellingProduct.position, 'flagEquipment': crossSellingProduct.flagEquipment }));
    });

    newCrossSellings.forEach((crossSellingProduct: any) => {
      const data = {
        'product': '/api/v2/products/' + this.model.id,
        'crossSelling': '/api/v2/products/' + crossSellingProduct.crossSelling.id,
        'position': crossSellingProduct.position,
        'flagEquipment': crossSellingProduct.flagEquipment,
      };

      requests.push(this.crossSellingResource.create(data));
    });

    deleteUpSellings.forEach((upSellingProduct: any) => {
      const id = `product=${this.model.id};upSelling=${upSellingProduct.upSelling.id}`;

      requests.push(this.upSellingResource.remove(id));
    });

    patchUpSellings.forEach((upSellingProduct: any) => {
      const id = `product=${this.model.id};upSelling=${upSellingProduct.upSelling.id}`;

      requests.push(this.upSellingResource.update(id, {'position': upSellingProduct.position }));
    });

    newUpSellings.forEach((upSellingProduct: any) => {
      const data = {
        'product': '/api/v2/products/' + this.model.id,
        'upSelling': '/api/v2/products/' + upSellingProduct.upSelling.id,
        'position': upSellingProduct.position
      };

      requests.push(this.upSellingResource.create(data));
    });

    forkJoin(requests)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

        if ('list' === redirection) {
          if (this.state.current.name === 'spare-part.edit.cross-up-sells') {
            this.state.go('spare-part.list');
          } else {
            this.state.go('product.list');
          }

          return;
        }

        if (this.state.current.name === 'spare-part.edit.cross-up-sells') {
          this.state.go('spare-part.edit.cross-up-sells',   { id: this.model.id }, { reload: true });
        } else {
          this.state.go('product.edit.cross-up-sells',  { id: this.model.id }, { reload: true });
        }
      })
    ;
  }

  public cancelForm(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() =>  {
        if (this.state.current.name === 'spare-part.edit.cross-up-sells') {
          this.state.go('spare-part.list');
        } else {
          this.state.go('product_new.list');
        }
    })
    ;
  }

  public setHasUpdates(e: boolean) {
    this.hasUpdates = e;
  }
}
