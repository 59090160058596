import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class ProformaInvoiceItemModel {
  '@id': string;
  id: number|string;
  sku: string;
  supplierReference: string;
  initialQuantity: number;
  quantity: number;
  unitPrice: number;
  price: number;
  unitCubicMeter?: number;
  ean?: string;
  sageName?: string;
  proformaInvoiceIdentifier?: string;

  constructor(object: IHydraMember) {
    this['@id'] = object['@id'];
    this.id = object.id;
    this.sku = object.masterProduct ? object.masterProduct.sku : null;
    this.supplierReference = object.supplierReference;
    this.initialQuantity = object.initialQuantity;
    this.quantity = object.quantity;
    this.unitPrice = object.unitPrice;
    this.price = object.price;
    this.unitCubicMeter = object.unitCubicMeter;
    this.ean = object.masterProduct ? object.masterProduct.ean : null;
    this.sageName = object.masterProduct ? object.masterProduct.sageName : null;
    this.proformaInvoiceIdentifier = object.proformaInvoice ? object.proformaInvoice.identifier : null;
  }
}
