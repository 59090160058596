import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { LoginComponent } from '@components/login/login.component';
import { SnackbarModule } from '@components';
import { AuthResource } from '@components/login/resources/auth.resource';
import {OauthValidateComponent} from '@components/login/oauth-validate.component';

@NgModule({
  declarations: [
    LoginComponent,
    OauthValidateComponent
  ],
  entryComponents: [
    LoginComponent,
    OauthValidateComponent
  ],
  imports: [
    CommonModule,
    GenericFormModule,
    ReactiveFormsModule,
    SnackbarModule,
  ],
  providers: [
    AuthResource,
  ]
})
export class LoginModule {}
