import { AbstractComponent } from '@components/generic/abstract.component';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { animate, style, transition, trigger } from '@angular/animations';

const BASE_POSITION = 'translateX(100%)';
const BASE_BG_COLOR = 'rgba(0, 0, 0, 0)';

/**
 * Display a sliding sidebar coming from the right of the screen.
 */
@Component({
  selector: 'app-sliding-sidebar',
  template: require('./sliding-sidebar.component.html'),
  styles: [require('./sliding-sidebar.component.scss')],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({
          transform: BASE_POSITION,
          background: BASE_BG_COLOR,
        }),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({
          transform: BASE_POSITION,
          background: BASE_BG_COLOR,
        }))
      ]),
    ])
  ],
})
export class SlidingSidebarComponent extends AbstractComponent {

  @Input() public open: boolean;
  @Input() public width: string = '90%';

  @Output() public onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public closeSlidingSidebar(): void {
    this.open = false;

    // maintain animation on destroy
    setTimeout(() => {
      this.onClose.emit();
    }, 200);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (('Escape' === event.key || 'ArrowRight' === event.key) && this.open) {
      this.closeSlidingSidebar();
    }
  }
}
