export default stockRoutes;

/** @ngInject */
function stockRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'stocks',
    title: '',
  };

  $stateProvider
    .state('stocks', {
      url: '/stocks',
      parent: 'walipush',
      abstract: true,
      breadcrumb: { label: 'PAGE.STOCK_VIEW.TITLE' }
    })
    .state('stocks.view', {
      url: `?
        page&
        byPage&
        stockType&
        stockSku&
        warehouse
      `,
      views: {
        'page@layout': {
          template: '<app-stock-view></app-stock-view>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_VIEW.TITLE',
      })
    })
    .state('stocks.arrivals', {
      url: `/arrivals?
        page&
        byPage&
        container&
        warehouse
      `,
      breadcrumb: { label: 'PAGE.ARRIVAL_VIEW.TITLE' },
      views: {
        'page@layout': {
          template: '<app-arrival-view></app-arrival-view>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ARRIVAL_VIEW.TITLE',
      })
    })
    .state('stocks.import', {
      url: `/imports?
        page&
        byPage&
      `,
      views: {
        'page@layout': {
          template: '<app-stock-import-list></app-stock-import-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_IMPORT.TITLE',
      })
    })
    .state('stocks.stock_regularization_package', {
      url: `/stock-regularization-packages?
        page&
        byPage&
        sku&
        origin&
        startDate&
        endDate
      `,
      breadcrumb: {
        label: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.TITLE',
        stateName: 'stocks.stock-regularization-package'
      },
      views: {
        'page@layout': {
          template: '<app-stock-regularization-package-list></app-stock-regularization-package-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.TITLE',
      })
    })
  ;
}
