import { ElementRef, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import { UploadModule } from '@progress/kendo-angular-upload';
import 'tinymce/tinymce';
import { defineLocale, frLocale } from 'ngx-bootstrap';
import {
  ApplicationModule,
  AttributeSetModule,
  BannerModule,
  CarrierFamilyModule,
  CarrierModule,
  CatalogsModule,
  CategoriesModule,
  CategoriesMarketplaceModule,
  CMSModule,
  CommercialOperationsModule,
  CustomerModule,
  DiscountModule,
  AutomaticDiscountModule,
  ExportLogisticsModule,
  GenericFormModule,
  LoaderModule,
  ManufacturerModule,
  NotationModule,
  OrderModule,
  OrderManagerModule,
  OrderCollection,
  PageTitleModule,
  PartialsModule,
  PaymentCarrierIconsModule,
  ProductModule,
  RabbitMqModule,
  RedirectsModule,
  RefundsModule,
  FooterModule,
  ReverseModule,
  ServiceModule,
  ShippingPriceModule,
  SnackbarModule,
  StockModule,
  StockMovementModule,
  StockShootingModule,
  SuperProductModule,
  TabModule,
  TabManagementModule,
  TaskManagerModule,
  TaskTypeModule,
  UsersModule,
  UnpairedModule,
  GenericModule,
  WarehousesModule,
  WarrantyModule,
  AttributeModule,
  AttributeFamilyModule,
  LoginModule,
  DashboardModule,
  BreadcrumbModule,
  ExportModule,
  QualityExportModule,
  SalesLogExportModule,
  MarketplacesConfigureModule,
  TrackingSalesModule,
  WebsiteCategoryModule,
  WebsiteModuleModule,
  WebsiteHomepageModule,
  SparePartModule,
  SavModule,
  EstimateModule,
  GrcModule,
  CommitmentModule,
  RoomModule,
  AddressingModule,
  LogisticWaveModule,
  LitigationModule,
  OneyOpcModule,
  EavAttributeModule,
  SupplierModule,
  ProformaInvoiceModule,
  GlossaryModule,
  ContainerModule
} from '@components';
import { HybridHelper, InjectionHelper, UrlHelper, OrderNewHelper } from '@helpers';
import { SessionHelper } from './helpers/session.helper';
import { AuthorizationInterceptor, ErrorHandlerInterceptor, PendingInterceptor } from '@interceptors';
import { ResourceModule } from '@resources/resource.module';
import {
  AuthService,
  CsvDataService,
  DatepickerThemingService,
  ErrorService,
  FormNotifierService,
  ProductNotifierService,
  RteEmbedPagesService,
  OrderDuplicateService
} from '@services';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import localeNl from '@angular/common/locales/nl';
import localeGb from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeLu from '@angular/common/locales/lu';
import localePl from '@angular/common/locales/pl';
import { HeaderTasksStatsModule } from '@components/header-tasks-stats/header-tasks-stats.module';
import { PipesModule } from './pipes/pipes.module';
import { GenericListNotifierService } from '@services/generic-list-notifier.service';


// define french locale for bootstrap plugins
defineLocale('fr', frLocale);

// registerLocaleData for all application
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localePt);
registerLocaleData(localeGb);
registerLocaleData(localeDe);
registerLocaleData(localeLu);
registerLocaleData(localePl);

@NgModule({
  declarations: [],
  imports: [
    AttributeSetModule,
    BannerModule,
    BrowserAnimationsModule,
    BrowserModule,
    ApplicationModule,
    AttributeModule,
    AttributeFamilyModule,
    CarrierFamilyModule,
    CarrierModule,
    CatalogsModule,
    CategoriesModule,
    CategoriesMarketplaceModule,
    CMSModule,
    CommercialOperationsModule,
    DiscountModule,
    AutomaticDiscountModule,
    FormsModule,
    GenericFormModule,
    HeaderTasksStatsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => AuthService.getToken(),
      }
    }),
    LoaderModule.forRoot(),
    LoginModule,
    DashboardModule,
    ManufacturerModule,
    NotationModule,
    OrderModule,
    OrderManagerModule,
    OrderCollection,
    PageTitleModule.forRoot(),
    PartialsModule,
    PaymentCarrierIconsModule,
    ProductModule,
    RabbitMqModule,
    ReactiveFormsModule,
    RedirectsModule,
    RefundsModule,
    FooterModule,
    ResourceModule.forRoot(),
    ReverseModule,
    CustomerModule,
    SnackbarModule.forRoot(),
    ServiceModule,
    ShippingPriceModule,
    StockModule,
    StockMovementModule,
    StockShootingModule,
    SuperProductModule,
    TabModule,
    TabManagementModule,
    TaskManagerModule,
    TaskTypeModule,
    UsersModule,
    GenericModule,
    UpgradeModule,
    UploadModule,
    WarehousesModule,
    WarrantyModule,
    ExportLogisticsModule,
    PipesModule,
    BreadcrumbModule,
    ExportModule,
    QualityExportModule,
    SalesLogExportModule,
    MarketplacesConfigureModule,
    TrackingSalesModule,
    WebsiteCategoryModule,
    WebsiteModuleModule,
    WebsiteHomepageModule,
    SparePartModule,
    SavModule,
    EstimateModule,
    GrcModule,
    CommitmentModule,
    UnpairedModule,
    RoomModule,
    AddressingModule,
    LogisticWaveModule,
    LitigationModule,
    OneyOpcModule,
    EavAttributeModule,
    SupplierModule,
    ProformaInvoiceModule,
    GlossaryModule,
    ContainerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PendingInterceptor, multi: true },
    { provide: POPUP_CONTAINER, useFactory: () => ({ nativeElement: document.body } as ElementRef) },
    {
      provide: LOCALE_ID,
      deps: [SessionHelper],
      useFactory: () => SessionHelper.getUILanguage()
    },
    HybridHelper.buildProviderForUpgrade('$rootScope', 'RootscopeService'),
    HybridHelper.buildProviderForUpgrade('$state', 'StateService'),
    HybridHelper.buildProviderForUpgrade('$location', 'LocationService'),
    HybridHelper.buildProviderForUpgrade('$dialog', 'DialogService'),
    HybridHelper.buildProviderForUpgrade('$translate', 'TranslationService'),
    HybridHelper.buildProviderForUpgrade('CountryService', 'CountryService'),
    CsvDataService,
    ErrorService,
    OrderNewHelper,
    UrlHelper,
    SessionHelper,
    AuthService,
    OrderDuplicateService,
    FormNotifierService,
    DatepickerThemingService,
    JwtHelperService,
    ProductNotifierService,
    GenericListNotifierService,
    RteEmbedPagesService,
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule, injector: Injector, dptheming: DatepickerThemingService) {
    InjectionHelper.injector = injector;
  }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['ammsFrontendApp']);
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
