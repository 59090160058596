import { ICommercialOperations, IWebsiteUrls } from '@components/commercial-operations/models/commercial-operations.interface';
import { ICommercialOperationsTranslation } from '@components/commercial-operations/models/commercial-operations.interface';
import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ICountry } from '@interfaces';
import { SessionHelper } from '@helpers';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import * as moment from 'moment';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';

export class CommercialOperationsModelList implements ICommercialOperations {
  '@id': string;
   id: string;
   marketplace: string;
   marketplaces: any;
   type: string;
   startDate: any|null;
   startTime: any;
   endDate: any|null;
   endTime: any;
   translations: ICommercialOperationsTranslation[];
   priority: number;
   defaultSorting: string;
   defaultDirection: string|null;
   countryName: string;
   country: ICountry;
   title: string[];
   productMarketplaceCount: number;
   buttonLinks: IButtonLinks[];
   archived: boolean;
   goodDeals: boolean;
   parent: string|null;
   parentId: number;

  constructor(commercialOperation: IHydraMember) {
    const countries = SessionHelper.getCountries();
    const country: ICountry = countries.find(c => commercialOperation.country.code === c.code);
    const title: string[] = [];
    country.locales.map(locale => title.push(commercialOperation.translations[locale].title));


    this.marketplace = SessionHelper.getAllMarketplaces().find(marketplace => marketplace.code === commercialOperation.marketplace)
      ? SessionHelper.getAllMarketplaces().find(marketplace => marketplace.code === commercialOperation.marketplace).commercialName
      : ''
    ;

    this.marketplaces = commercialOperation.marketplaces;
    this['@id'] = commercialOperation['@id'];
    this.id = commercialOperation.id;
    this.type = commercialOperation.type;
    this.startDate = (commercialOperation.startDate && commercialOperation.startTime) ?
      moment(commercialOperation.startDate).startOf('day')
        .add(commercialOperation.startTime.getHours(), 'hours')
        .add(commercialOperation.startTime.getMinutes(), 'minutes')
        .add(commercialOperation.startTime.getSeconds(), 'seconds')
        .format(DATE_FULL_FORMAT) : commercialOperation.startDate
        ?
        moment(commercialOperation.startDate).startOf('day').format(DATE_SHORT_FORMAT) :
        null;
    this.startTime = commercialOperation.startDate ? new Date(commercialOperation.startDate) : null;
    this.endDate = (commercialOperation.endDate && commercialOperation.endTime) ?
      moment(commercialOperation.endDate).startOf('day')
        .add(commercialOperation.endTime.getHours(), 'hours')
        .add(commercialOperation.endTime.getMinutes(), 'minutes')
        .add(commercialOperation.endTime.getSeconds(), 'seconds')
        .format(DATE_FULL_FORMAT) : commercialOperation.endDate
        ?
        moment(commercialOperation.endDate).endOf('day').format(DATE_SHORT_FORMAT) :
        null;
    this.endTime = commercialOperation.endDate ? new Date(commercialOperation.endDate) : null;
    this.translations = commercialOperation.translations;
    this.priority = commercialOperation.priority;
    this.defaultSorting = commercialOperation.defaultSorting;
    this.defaultDirection = commercialOperation.defaultDirection;
    this.countryName = country.name;
    this.title = title;
    this.productMarketplaceCount = commercialOperation.productMarketplaceCount;
    this.buttonLinks = this.formatLinks(commercialOperation.websiteUrls);
    this.archived = commercialOperation.archived;
    this.goodDeals = commercialOperation.goodDeals;
    this.parent = commercialOperation.parent;
    this.parentId = commercialOperation.parentId;
  }

  private formatLinks(websiteUrls: IWebsiteUrls) {
    return websiteUrls
    ? Object.keys(websiteUrls).map(key => {
      return {
        translationParams: key,
        url: websiteUrls[key]
      };
    })
    : null;
  }
}

