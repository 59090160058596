import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';
import { IProductMarketplace } from '@components/product/interfaces/product-form.interface';

interface ISelectableCountry {
  id: number;
  code: string;
  label: string;
}

interface ISelectableMarketplace {
  code: string;
  label: string;
}

@Component({
  selector: 'app-duplicate-product',
  template: require('./duplicate-product.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class DuplicateProductComponent extends AbstractFormComponent implements OnInit {

  public form: FormGroup;
  public countries: ISelectableCountry[];
  public currentCountry: ICountry;
  public open: boolean = false;
  @Input() public productMarketplaces: { [marketplaceCode: string]: IProductMarketplace };
  public marketplaces: ISelectableMarketplace[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.currentCountry = SessionHelper.getCountry();
    this.countries = SessionHelper.getCountries().map((country: ICountry) => {
      return {
        id: country.id,
        code: country.code,
        label: country.translations[this.currentLocale].name,
      };
    });

    if (undefined !== this.productMarketplaces) {
      this.marketplaces = Object.keys(this.productMarketplaces).map((marketplaceCode: string) => {
        return {
          code: marketplaceCode,
          label: marketplaceCode,
        };
      });
    }
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      country: [{
        label: this.currentCountry.translations[this.currentLocale].name,
        id: this.currentCountry.id,
        code: this.currentCountry.code,
      }],
      sku: [''],
      marketplaceSource: [''],
      priceToMarketplaces: [[]],
      openAiTranslation: [false],
      crossSelling: [false],
      upSelling: [false]
    });

    this.form.get('marketplaceSource').setValue(`Site - ${this.form.get('country').value.label}`);
  }

  public closeModal(): void {
    this.open = false;
  }

  public openModal(): void {
    this.open = true;
    this.createForm();
  }

  public duplicateProduct(): void {
    this.resource.duplicate(
      +this.state.params.id,
      +this.form.get('sku').value.id,
      !!this.form.get('openAiTranslation').value,
      !!this.form.get('crossSelling').value,
      !!this.form.get('upSelling').value,
      this.form.get('priceToMarketplaces').value.map((marketplace: ISelectableMarketplace) => marketplace.code),
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.open = false;
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
        this.state.go(`${this.resource.routeName}.edit`, {id: this.state.params.id}, {reload: true});
      });
  }

  public resetSku(): void {
    this.form.get('marketplaceSource').setValue(`Site - ${this.form.get('country').value.label}`);
    this.form.get('sku').reset();
  }
}
