import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList, TemplateRef,
  ViewChildren,
} from '@angular/core';
import { IColumnSortedEvent } from './services/sort.service';
import { AuthService } from '@services';
import { UrlHelper } from '../../../helpers';
import { ShowDetails, ShowDetailsInterface } from '@components/generic/List/show-details';
import { ShowDetailsDirective } from '@components/generic/List/show-details';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { IListColumn } from '@interfaces/IListColumn';

/**
 * The generic result list component is used for displaying a list of result in a table.
 *
 * Features:
 *  - output `onSortList` event when clicking on a column marked as sortable
 *  - display a column for bulk action with a checkbox for each items of the list,
 *  emit an `onBulkDelete` event by clicking on the massive btn
 *  - display detailed information for items with a dynamically created component
 *  if the resource give to it a `showDetailsComponent`
 */
@Component({
  selector: 'app-generic-result-list',
  template: require('./generic-result-list.component.html'),
  styles: [require('./generic-result-list.component.scss')],
})
export class GenericResultListComponent extends AbstractComponent {

  public toggleAll: boolean;
  public hasMassiveActions: boolean;
  public showDetailsComponent: ShowDetailsInterface;
  public allShowDetailsOpen = false;

  /**
   * Column list defined in the resource
   */
  public columnList: IListColumn[] = [];

  /**
   * Translation key from resource use for retrieving the column translation
   */
  public translationKey: string = '';

  /**
   * The items to loop in.
   */
  @Input() public items: any;

  /**
   * Template row display
   */
  @Input() public rowTemplate?: TemplateRef<any> = null;

  /**
   * Fired when user sort column
   */
  @Output() public onSortList: EventEmitter<any> = new EventEmitter();

  /**
   * Fired when user select bulk delete
   */
  @Output() public onBulkDelete: EventEmitter<any> = new EventEmitter();

  /**
   * Fired when user delete an item that is marked to custom delete.
   */
  @Output() public onCustomDelete: EventEmitter<any> = new EventEmitter();

  @Output() public customAction: EventEmitter<any> = new EventEmitter();

  @ViewChildren(ShowDetailsDirective) showDetailsHosts: QueryList<ShowDetailsDirective>;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') protected dialog: any,
    protected urlHelper: UrlHelper,
    protected componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super($translate, authService, resource, state);

    this.hasMassiveActions = this.resource.hasMassiveActions;
    this.showDetailsComponent = this.resource.showDetailsComponent;
    this.columnList = this.resource.getDefaultListColumns() || this.resource.listColumns;
    this.translationKey = this.resource.translationKey;
  }

  /**
   * Emit event to avert parent that we want sorted data.
   */
  public onSort(event: IColumnSortedEvent) {
    this.onSortList.emit(event);
  }

  /**
   * Create a new row with a dynamically created component in it.
   * This component must implement showDetailsInterface and comes from resource.
   * Data comes from showDetailsData in model associated to resource
   */
  public showDetails(row: any, index: number): void {
    if (!row.showDetails) {
      row.showDetails = true;

      const showDetailItem = new ShowDetails(this.resource.showDetailsComponent).component;
      const viewContainerRef = this.showDetailsHosts
        .find(showDetailsHost => showDetailsHost.viewContainerRef.element.nativeElement.id.includes(index)).viewContainerRef
      ;
      viewContainerRef.clear();

      const componentFactory = this.componentFactoryResolver
        .resolveComponentFactory(showDetailItem)
      ;

      const componentRef = viewContainerRef.createComponent(componentFactory);
      componentRef.instance.data = row.showDetailsData;

      return;
    }

    delete row.showDetails;
  }

  public showAllDetails(): void {
    this.items.forEach((item: any, index: number) => {
      this.allShowDetailsOpen ? item.showDetails = true : delete item.showDetails;
      this.showDetails(item, index);
    });

    this.allShowDetailsOpen = !this.allShowDetailsOpen;
  }

  /**
   * Displays a button according to the `hasMassiveActions` from resource to apply mass action on items.
   * For the moment only delete action will be used.
   */
  public showMassiveActions(): boolean {
    return this.hasMassiveActions && this.items && this.items.filter((row: any) => row.toggled === true).length > 0;
  }

  /**
   * Select all items by checking the boxes.
   */
  public selectAll(): void {
    this.items.forEach((row: any) => row.toggled = this.toggleAll);
  }

  /**
   * Emit `onBulkDelete` event after a confirmation popup.
   */
  public deleteSelectedItems(): void {
    this.dialog.confirm(this.translate(`PAGE.${this.resource.translationKey}.CONFIRM.DELETE_MULTIPLE`))
      .then(() => this.onBulkDelete.emit(this.items))
    ;
  }

  public delete(event: any): void {
    this.onCustomDelete.emit(event);
  }
}
