import { ITabList, ITabListTranslation } from '@components/tab/models/tab-list.interface';
import { SessionHelper } from '@helpers/session.helper';
import { ITab } from './tab.interface';

export class TabListModel implements ITabList {
  '@id': string;
  id: string;
  name: string;
  type: string|string[];
  children: ITabList[];
  position: number|null;
  parent: string|null;
  editUrl?: string;
  editUrlId: string;
  fontColor: string;
  backgroundColor: string;
  active: boolean;
  [key: string]: any;
  url: string|null;

  constructor(tab: ITabList = {
    '@id': '',
    id: '',
    name: '',
    type: '',
    children: [],
    position: null,
    parent: '',
    editUrl: '',
    editUrlId: '',
    fontColor: '',
    backgroundColor: '',
    active: false,
    url: null
}) {
    this['@id'] = tab['@id'];
    this.id = tab.id;
    this.name = tab.translations[SessionHelper.getLocale()] ? tab.translations[SessionHelper.getLocale()].name : null;
    this.type = TabListModel.getType(tab);
    this.children = tab.children ? tab.children.map(child => new TabListModel(child)) : [];
    this.position = tab.position;
    this.parent = tab.parent;
    this.editUrl = tab.category ? 'categories' : null;
    this.editUrlId = tab.category ? tab.category.split('/').pop() : null;
    this.fontColor = tab.fontColor ? tab.fontColor : null;
    this.backgroundColor = tab.backgroundColor ? tab.backgroundColor : null;
    this.active = tab.active;
    const urls: string[] = [];
    Object.values(tab.translations).forEach((tabTranslation: ITabListTranslation) => {
      if (tabTranslation.url !== null && tab.type === 'url') {
        urls.push(tabTranslation.url);
      }
    });
    this.url = urls.join(', ').replace(/(, )$/, '') !== '' ? urls.join(', ').replace(/(, )$/, '') : 'PAGE.TAB.LIST.NA';
}

  public static getType(tab: ITabList): string|string[] {
    const pageName: string = 'PAGE.TAB.NAME';

    switch (tab.type) {
      case 'url':
        return [pageName, 'PAGE.TAB.FORM.SPECIFIC_URL'];
      case 'cms':
        return [pageName, 'PAGE.TAB.FORM.CMS'];
      case 'pro':
        return [pageName, 'PAGE.TAB.FORM.PRO'];
      case 'universe':
        return [pageName, 'PAGE.TAB.FORM.CATEGORY', '-', tab.children && tab.children.length > 0 ?
           'PAGE.CMS.FORM.CRUMB.UNIVERSE'
           : 'PAGE.CMS.FORM.CRUMB.SHELF'
          ];
      case 'commercial-operation':
        return [pageName, 'PAGE.TAB.FORM.COMMERCIAL_OPERATIONS'];
      case 'room':
        return [pageName, 'PAGE.TAB.FORM.ROOM'];
      case 'collapse':
      default:
        return [pageName, 'PAGE.TAB.FORM.COLLAPSE'];
    }
  }
}
