import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { RefundsListModel } from '@components/transactions/refunds/models';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants/date';
import { IExportOptions } from '@interfaces/exportOptions.interface';
import { SessionHelper } from '@helpers';


const entryPoint = '/v2/transactions';
const exportOptions: IExportOptions[] = [{
    entryPoint: `${entryPoint}/export?operation=debit`,
    responseType: 'text',
    type: 'text/csv',
    filename: `refunds_${moment().format()}.csv`,
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: true
  }];

@Injectable()
@Resource({
  routeName: 'refunds',
  entryPoint,
  exportOptions,
  exportHeaders: { Accept: 'text/csv' },
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    operation: 'debit',
    transactionCommercialEntity : SessionHelper.getCountry().commercialEntity.id
  }),
  translationKey: 'REFUNDS',
  listModel: RefundsListModel,
  buttonActionMultipleAvailable: true,
  deleteAvailable: true,
  createAvailable: false,
  updateAvailable: false,
  isHydra: true,
})
export class TransactionsResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    if (params.createdAt) {
      params['createdAt[after]'] = params.createdAt = moment(params.createdAt).startOf('day').format(DATE_SHORT_INTERNATIONAL_FORMAT);
    }

    return super.cGet(params, options);
  }

  public bulk(transactions: string[], status: string): Observable<any> {
    return super.create({ transactions: transactions, status: status }, { entryPoint: '/v2/transactions/bulk' });
  }

  public generateRefundInvoices(): Observable<any> {
    return super.create({}, {
      entryPoint: '/v2/generate-invoices'
    });
  }
}
