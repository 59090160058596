export default stockMovementRoutes;

/** @ngInject */
function stockMovementRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'orders',
    title: '',
  };

  $stateProvider
    .state('stock-movement', {
      url: '/stock-movements',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'PAGE.STOCK_MOVEMENT.TITLE',
        stateName: 'stock-movements'
      }
    })
    .state('stock-movement.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-stock-movement-list></app-stock-movement-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_MOVEMENT.TITLE',
      })
    })
    .state('stock-movement.new', {
      url: '/new',
      views: {
        'page@layout': {
          template: '<app-stock-movement-form></app-stock-movement-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_MOVEMENT.TITLE',
      })
    })
    .state('stock-movement.detail', {
      url: '/:id',
      views: {
        'page@layout': {
          template: '<app-stock-movement-result-detail></app-stock-movement-result-detail>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_MOVEMENT.TITLE',
      })
    })
  ;
}
