import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ICommercialEntity } from '@interfaces';

export class SupplierCommercialEntityModel {
  commercialEntity: ICommercialEntity;
  sageIdentifier: string;

  constructor(object: IHydraMember) {
    this.commercialEntity = object.commercialEntity;
    this.sageIdentifier = object.sageIdentifier;
  }
}
