import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import {StockMovementLineModel} from '@components/stock-movement/stock-movement-line.model';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/stock_movement_lines',
  isHydra: true,
  listModel: StockMovementLineModel,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order[id]': 'DESC'
  }),
})
export class StockMovementLineResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public bulkReceiveMovementLines(stockMovementLines: string[]): Observable<any> {
    return super.create({ movementLines: stockMovementLines }, { entryPoint: '/v2/bulk/stock_movement_lines/receive' });
  }

  public bulkTransferMovementLines(stockMovementLines: string[], toWarehouse: string): Observable<any> {
    return super.create({ movementLines: stockMovementLines, toWarehouse : toWarehouse }, { entryPoint: '/v2/bulk/stock_movement_lines/transfer' });
  }
}
