import { ArrivalViewComponent } from '@components/stock/arrival-view/arrival-view.component';
import { StockViewComponent } from '@components/stock/stock-view/stock-view.component';
import { StockImportListComponent } from '@components/stock/stock-import/stock-import-list.component';
import { NgModule } from '@angular/core';
import {GenericFormModule, GenericModule} from '@components';
import { CommonModule } from '@angular/common';
import { ArrivalViewResource } from '@components/stock/arrival-view/arrival-view.resource';
import { StockViewResource } from '@components/stock/stock-view/stock-view.resource';
import {DialogModule, WindowModule} from '@progress/kendo-angular-dialog';
import {MultiSelectModule, SharedModule} from '@progress/kendo-angular-dropdowns';
import {PipesModule} from '../../pipes/pipes.module';
import {BsDatepickerModule} from 'ngx-bootstrap';
import { StockRegularizationPackageListComponent } from '@components/stock/stock-regularization-package/stock-regularization-package-list.component';
import { StockRegularizationPackageListResource } from '@components/stock/stock-regularization-package/stock-regularization-package-list.resource';

@NgModule({
  declarations: [
    ArrivalViewComponent,
    StockRegularizationPackageListComponent,
    StockViewComponent,
    StockImportListComponent,
  ],
  entryComponents: [
    ArrivalViewComponent,
    StockRegularizationPackageListComponent,
    StockViewComponent,
    StockImportListComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule,
    MultiSelectModule,
    BsDatepickerModule
  ],
  exports: [
    ArrivalViewComponent,
    StockRegularizationPackageListComponent,
    StockViewComponent,
    StockImportListComponent,
  ],
  providers: [
    ArrivalViewResource,
    StockRegularizationPackageListResource,
    StockViewResource,
  ]
})
export class StockModule {}
