export class StockMovementLineModel {
  id: number;
  from: string;
  to: string;
  order: any;
  sku: string;
  quantity: number;
  receivedQuantity: number;
  received: boolean;
  transferred: boolean;
  outflow: boolean;
  entry: boolean;
  stockMovementId: number;
  orderType: string;
  checked: boolean;

  constructor(stockMovementLine: any) {
    this.id = stockMovementLine.id;
    this.from = stockMovementLine.stockMovement.fromWarehouse ? stockMovementLine.stockMovement.fromWarehouse.name : null;
    this.to = stockMovementLine.stockMovement.toWarehouse ? stockMovementLine.stockMovement.toWarehouse.name : null;
    this.order = stockMovementLine.stockMovement.order;
    this.sku = stockMovementLine.masterProduct.sku;
    this.quantity = stockMovementLine.quantity;
    this.receivedQuantity = stockMovementLine.receivedQuantity;
    this.received = stockMovementLine.received;
    this.transferred = stockMovementLine.transferred;
    this.outflow = stockMovementLine.outflowDocumentNumber;
    this.entry = stockMovementLine.entryDocumentNumber;
    this.stockMovementId = stockMovementLine.stockMovement.id;
    this.orderType = stockMovementLine.stockMovement.type;
    this.checked = false;
  }
}
