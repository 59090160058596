import { ISku, ISliderItems } from '@components/website-homepage/website-homepage-slider-items/models/slider-items.interface';
import { IProductMarketplace } from '@components/website-homepage/website-homepage-slider-items/models/slider-items.interface';
import {  DATE_SHORT_FORMAT } from '@constants/date';
import * as moment from 'moment';

export class SliderItemsModel implements ISliderItems {
  id: string;
  description: string;
  highDescriptionColor: string;
  link: string;
  locale: string;
  lowDescription: string;
  lowDescriptionColor: string;
  position: number;
  productMarketplace: IProductMarketplace;
  skuLabel: string;
  title: string;
  titleColor: string;
  webPath: string;
  sku: string;
  dateStart: string;
  startTime?: any;
  dateEnd: string;
  endTime?: any;
  mediaQuery: string;

  constructor(sliderItem: ISliderItems) {
    this.id = sliderItem.id;
    this.description = sliderItem.description ? sliderItem.description : '';
    this.highDescriptionColor = sliderItem.highDescriptionColor ? sliderItem.highDescriptionColor : '#000000';
    this.link = sliderItem.link ? sliderItem.link : '';
    this.locale = sliderItem.locale;
    this.lowDescription = sliderItem.lowDescription ? sliderItem.lowDescription : '';
    this.lowDescriptionColor = sliderItem.lowDescriptionColor ? sliderItem.lowDescriptionColor : '#000000';
    this.position = sliderItem.position;
    this.productMarketplace = sliderItem.productMarketplace;
    this.skuLabel = sliderItem.productMarketplace && sliderItem.productMarketplace.product ? sliderItem.productMarketplace.product.sku : null;
    this.sku = sliderItem.productMarketplace ? sliderItem.productMarketplace.id : '';
    this.title = sliderItem.title ? sliderItem.title : '';
    this.titleColor = sliderItem.titleColor ? sliderItem.titleColor : '#000000';
    this.webPath = sliderItem.webPath;
    this.dateStart = sliderItem.dateStart ? moment(sliderItem.dateStart).startOf('day').format(DATE_SHORT_FORMAT) : null;
    this.startTime = sliderItem.dateStart ? new Date(sliderItem.dateStart) : null;
    this.dateEnd = sliderItem.dateEnd ? moment(sliderItem.dateEnd).endOf('day').format(DATE_SHORT_FORMAT) : null;
    this.endTime = sliderItem.dateEnd ? new Date(sliderItem.dateEnd) : null;
    this.mediaQuery = sliderItem.mediaQuery;
  }
}
