import { Component, Inject, OnInit } from '@angular/core';
import { SessionHelper } from '@helpers/session.helper';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { IMarketplace } from '@interfaces/marketplace.interface';

export interface ISidebarItem {
  id?: string;
  label: string;
  icon: string;
  active: boolean;
  open?: boolean;
  link?: ISidebarLink;
  subMenu?: ISidebarSubMenu[];
  notGranted?: boolean;
}

export interface ISidebarSubMenu {
  id?: string;
  label: string;
  active: boolean;
  open?: boolean;
  link?: ISidebarLink;
  subSubMenu?: ISidebarSubMenu[];
  hidden?: boolean;
  notGranted?: boolean;
}

export interface ISidebarLink {
  route: string;
  params?: any;
  options?: string;
}

@Component({
  selector: 'app-sidebar',
  template: require('./sidebar.component.html'),
})

export class SidebarComponent extends AbstractComponent implements OnInit {

  public toggle: ISidebarItem[];
  public marketplaces: IMarketplace[] = SessionHelper.getMarketplaces();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.toggle = [
      {
        id: 'dashboardLink',
        label: 'SIDEBAR.LABEL.DASHBOARD',
        icon: 'fa-home',
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        active: false,
        link: {
          route: 'dashboard.revenues',
        },
      },
      {
        id: 'inventoryMenu',
        label: 'SIDEBAR.LABEL.INVENTORY',
        icon: 'fa-list',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        subMenu: [
          {
            id: 'productsLink',
            label: 'SIDEBAR.LABEL.PRODUCTS',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'product_new.list',
            },
          },
          {
            id: 'superProductsLink',
            label: 'SIDEBAR.LABEL.SUPER_PRODUCTS',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'super-product.list',
            },
          },
          {
            id: 'catalogsLink',
            label: 'SIDEBAR.LABEL.CATALOGS',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'catalogs.list',
            },
          },
          {
            id: 'commercialOperationsLink',
            label: 'SIDEBAR.LABEL.COMMERCIAL_OPERATIONS',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'commercial-operations.list',
            },
          },
          {
            id: 'categoriesMarketplaceLink',
            label: 'SIDEBAR.LABEL.CATEGORY_MARKETPLACES',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'categories-marketplace.list',
            }
          },
          {
            id: 'attributesListLink',
            label: 'SIDEBAR.LABEL.ATTRIBUTES_LIST',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'attribute.list'
            },
          },
          {
            id: 'attributeSetsLink',
            label: 'SIDEBAR.LABEL.ATTRIBUTE_SETS',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'attribute-sets.list',
            },
          },
          {
            id: 'unpairedLink',
            label: 'SIDEBAR.LABEL.UNPAIRED',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'unpaired.list',
            },
          },
          {
            id: 'eavAttributeListLink',
            label: 'SIDEBAR.LABEL.EAV_ATTRIBUTE',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'eav-attribute.list'
            }
          },
          {
            id: 'glossaryLink',
            label: 'SIDEBAR.LABEL.GLOSSARY',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'glossary.list'
            }
          },
        ],
      },
      {
        label: 'SIDEBAR.LABEL.ORDERS.TITLE',
        icon: 'fa-shopping-cart',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        subMenu: [
          {
            id: 'ordersLink',
            label: 'SIDEBAR.LABEL.ORDERS.ALL',
            active: false,
            link: {
              route: 'order_manager.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_ORDER_READ']),
          },
          {
            id: 'ordersLink',
            label: 'SIDEBAR.LABEL.ORDERS.SAV',
            active: false,
            link: {
              route: 'sav.orders_list',
            }
          },
          {
            id: 'ordersLink',
            label: 'SIDEBAR.LABEL.ORDERS.ESTIMATE',
            active: false,
            link: {
              route: 'estimate.orders_list',
            }
          },
          {
            id: 'ordersByMarketplaceLink',
            label: 'SIDEBAR.LABEL.ORDERS.BY_MARKETPLACE',
            active: false,
            open: false,
            subSubMenu: this.getMarketplaceList(),
          },
          {
            id: 'ordersNewShowLink',
            label: 'SIDEBAR.LABEL.ORDERS.NEW_SHOW',
            active: false,
            link: {
              route: 'order.new',
              params: { marketplace: 'showroom', orderType: 'order' },
            },
            hidden: !this.hasShowroomMarketplace(),
          },
          {
            id: 'ordersNewSavLink',
            label: 'SIDEBAR.LABEL.ORDERS.NEW_SAV',
            active: false,
            link: {
              route: 'sav.order_new',
            },
          },
          {
            id: 'OrderCollectionLink',
            label: 'SIDEBAR.LABEL.ORDERS.ORDER_COLLECTION',
            active: false,
            link: {
              route: 'order-collection.list',
            },
          },
          {
            id: 'refundsLink',
            label: 'SIDEBAR.LABEL.ORDERS.REFUNDS',
            active: false,
            link: {
              route: 'refunds.list',
            },
          },
          {
            id: 'sageExports',
            label: 'SIDEBAR.LABEL.ORDERS.SAGE_EXPORTS',
            active: false,
            link: {
              route: 'order_manager.sage_exports',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'stockImports',
            label: 'SIDEBAR.LABEL.ORDERS.STOCK_IMPORTS',
            active: false,
            link: {
              route: 'stocks.import',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'commitmentList',
            label: 'SIDEBAR.LABEL.ORDERS.COMMITMENT',
            active: false,
            link: {
              route: 'commitment.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'stockMovementList',
            label: 'SIDEBAR.LABEL.ORDERS.STOCK_MOVEMENTS',
            active: false,
            link: {
              route: 'stock-movement.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_PRIVATE_DATA']),
          },
          {
            id: 'stockShootingList',
            label: 'SIDEBAR.LABEL.ORDERS.REPATRIATED_SHOOTING',
            active: false,
            link: {
              route: 'stock-shooting.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_PRIVATE_DATA']),
          },
        ]
      },
      {
        label: 'SIDEBAR.LABEL.BUYER',
        icon: 'fa-couch',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_BUYER_AGENT']),
        subMenu: [
          {
            id: 'proformaInvoiceList',
            label: 'SIDEBAR.LABEL.BUYER.PROFORMA_INVOICE',
            active: false,
            link: {
              route: 'proforma_invoice.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_BUYER_AGENT']),
          },
          {
            id: 'supplierList',
            label: 'SIDEBAR.LABEL.BUYER.SUPPLIER',
            active: false,
            link: {
              route: 'supplier.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_BUYER_AGENT']),
          }
        ]
      },
      {
        id: 'grcMenu',
        label: 'SIDEBAR.LABEL.GRC',
        icon: 'fa-solid fa-headset',
        active: false,
        open: false,
        notGranted: this.isGranted(['ROLE_EXTERNAL_REVERSE']) && !this.isGranted('ROLE_WALISOFT_WAREHOUSE_AGENT'),
        subMenu: [
          {
            id: 'dashboard',
            label: 'SIDEBAR.LABEL.GRC_DASHBOARD',
            active: false,
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
            link: {
              route: 'grc.dashboard',
            },
          },
          {
            id: 'grc',
            label: 'SIDEBAR.LABEL.PRODUCT_INFOS',
            active: false,
            link: {
              route: 'grc.list',
            },
          },
        ],
      },
      {
        id: 'savMenu',
        label: 'SIDEBAR.LABEL.SAV',
        icon: 'fa-wrench',
        active: false,
        open: false,
        notGranted: this.isGranted(['ROLE_EXTERNAL_REVERSE']) && !this.isGranted('ROLE_WALISOFT_WAREHOUSE_AGENT'),
        subMenu: [
          {
            id: 'sparePartSearchLink',
            label: 'SIDEBAR.LABEL.SPARE_PARTS_SEARCH',
            active: false,
            link: {
              route: 'spare-part.search',
            },
            notGranted: this.isGranted(['ROLE_EXTERNAL_REVERSE']) && !this.isGranted('ROLE_WALISOFT_WAREHOUSE_AGENT'),
          },
          {
            id: 'sparePartListLink',
            label: 'SIDEBAR.LABEL.SPARE_PARTS',
            active: false,
            link: {
              route: 'spare-part.list',
            },
            notGranted: this.isGranted(['ROLE_EXTERNAL_REVERSE']) && !this.isGranted('ROLE_WALISOFT_WAREHOUSE_AGENT'),
          },
          {
            id: 'arrivalLink',
            label: 'SIDEBAR.LABEL.ARRIVAL_LIST',
            active: false,
            link: {
              route: 'sav.arrival_list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'logisticWaveList',
            label: 'SIDEBAR.LABEL.LOGISTIC_WAVE',
            active: false,
            link: {
              route: 'logistic-wave.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          }
        ],
      },
      {
        label: 'SIDEBAR.LABEL.CARRIERS',
        icon: 'fa-truck',
        active: false,
        open: false,
        notGranted: this.isGranted(['ROLE_EXTERNAL_REVERSE']) && !this.isGranted('ROLE_WALISOFT_WAREHOUSE_AGENT'),
        subMenu: [
          {
            id: 'carrierFamiliesLink',
            label: 'SIDEBAR.LABEL.CARRIER_FAMILIES',
            active: false,
            link: {
              route: 'carrierFamilies.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'carriersLink',
            label: 'SIDEBAR.LABEL.CARRIER.CARRIERS',
            active: false,
            link: {
              route: 'carrier.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'calculatorLink',
            label: 'SIDEBAR.LABEL.CALCULATOR',
            active: false,
            link: {
              route: 'carrier.calculator',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'reversesLink',
            label: 'SIDEBAR.LABEL.REVERSES',
            active: false,
            link: {
              route: 'reverses.list',
            },
          },
          {
            id: 'litigationsLink',
            label: 'SIDEBAR.LABEL.LITIGATIONS',
            active: false,
            link: {
              route: 'litigation.list',
            },
          },
          {
            id: 'warehousesLink',
            label: 'SIDEBAR.LABEL.WAREHOUSES',
            active: false,
            link: {
              route: 'warehouses.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'addressingLink',
            label: 'SIDEBAR.LABEL.ADDRESSING',
            active: false,
            link: {
              route: 'addressing.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'capacityLink',
            label: 'SIDEBAR.LABEL.CAPACITY',
            active: false,
            link: {
              route: 'carrier.capacity',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'dockedVolumeLink',
            label: 'SIDEBAR.LABEL.DOCKED_VOLUME',
            active: false,
            link: {
              route: 'carrier.docked_volume',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'carrierScalingLink',
            label: 'SIDEBAR.LABEL.ORDERS.CARRIER_SCALING',
            active: false,
            link: {
              route: 'order_manager.scaling_list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'exportLogisticsLink',
            label: 'SIDEBAR.LABEL.ORDERS.EXPORT_LOGISTICS',
            active: false,
            link: {
              route: 'export-logistics.export',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'shippingPriceLink',
            label: 'SIDEBAR.LABEL.SHIPPING_PRICE',
            active: false,
            link: {
              route: 'shipping-prices.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
        ]
      },
      {
        label: 'SIDEBAR.LABEL.STOCK',
        icon: 'fa-ship',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        subMenu: [
          {
            id: 'containerList',
            label: 'SIDEBAR.LABEL.STOCK.CONTAINER',
            active: false,
            link: {
              route: 'container.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_IMPORT_AGENT']),
          },
          {
            id: 'stockList',
            label: 'SIDEBAR.LABEL.STOCK.STOCK',
            active: false,
            link: {
              route: 'stocks.view',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'arrivalList',
            label: 'SIDEBAR.LABEL.STOCK.ARRIVAL',
            active: false,
            link: {
              route: 'stocks.arrivals',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_IMPORT_AGENT']),
          },
          {
            id: 'delayReasonOrderList',
            label: 'SIDEBAR.LABEL.ORDERS.DELAY_REASON_ORDER_LIST',
            active: false,
            link: {
              route: 'order_manager.delay_reason_order_list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_IMPORT_AGENT']),
          },
          {
            id: 'stockRegularizationPackageList',
            label: 'SIDEBAR.LABEL.STOCK.STOCK_REGULARIZATION_PACKAGE',
            active: false,
            link: {
              route: 'stocks.stock_regularization_package',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
        ]
      },
      {
        label: 'SIDEBAR.LABEL.WEBSITE',
        icon: 'fa-globe',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        subMenu: [
          {
            id: 'tabsLink',
            label: 'SIDEBAR.LABEL.TABS',
            active: false,
            link: {
              route: 'tabs.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'roomsLink',
            label: 'SIDEBAR.LABEL.ROOMS',
            active: false,
            link: {
              route: 'room.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'websiteCategoriesLink',
            label: 'SIDEBAR.LABEL.WEBSITE_CATEGORIES',
            active: false,
            link: {
              route: 'websiteCategories.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'websiteHomepageLink',
            label: 'SIDEBAR.LABEL.WEBSITE_HOMEPAGE',
            active: false,
            link: {
              route: 'websiteHomepage.slider-items',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'footerLink',
            label: 'SIDEBAR.LABEL.WEBSITE_FOOTER',
            active: false,
            link: {
              route: 'reinsurancePoints.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'ServiceLink',
            label: 'SIDEBAR.LABEL.SERVICES',
            active: false,
            link: {
              route: 'service.list',
            },
          },
          {
            id: 'notationsLink',
            label: 'SIDEBAR.LABEL.NOTATIONS',
            active: false,
            link: {
              route: 'notations.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'carrierIconsLink',
            label: 'SIDEBAR.LABEL.PAYMENT_CARRIER_ICONS',
            active: false,
            link: {
              route: 'paymentCarrierIcons.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'cmsLink',
            label: 'SIDEBAR.LABEL.CMS',
            active: false,
            link: {
              route: 'cms.list',
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'discountLink',
            label: 'SIDEBAR.LABEL.DISCOUNT',
            active: false,
            link: {
              route: 'discount.list'
            },
          },
          {
            id: 'automaticDiscountLink',
            label: 'SIDEBAR.LABEL.DISCOUNT_AUTOMATIC',
            active: false,
            link: {
              route: 'automatic-discount.list'
            },
            notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
          },
          {
            id: 'redirectsLink',
            label: 'SIDEBAR.LABEL.REDIRECTS',
            active: false,
            link: {
              route: 'redirects.list',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'bannerLink',
            label: 'SIDEBAR.LABEL.BANNERS',
            active: false,
            link: {
              route: 'banners.list',
            },
          },
          {
            id: 'websiteModulesLink',
            label: 'SIDEBAR.LABEL.WEBSITE_MODULES',
            active: false,
            link: {
              route: 'websiteModules.edit',
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          },
          {
            id: 'oneyOpcLink',
            label: 'SIDEBAR.LABEL.ONEY_OPC',
            active: false,
            link: {
              route: 'oney-opc.list'
            },
            notGranted: !this.isGranted([
              'ROLE_WALISOFT_AGENT',
            ]),
          }
        ]
      },
      {
        label: 'SIDEBAR.LABEL.USERS',
        icon: 'fa-user',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        subMenu: [
          {
            id: 'usersLink',
            label: 'SIDEBAR.LABEL.COLLABORATORS',
            active: true,
            link: {
              route: 'users.list'
            }
          },
          {
            id: 'customersLink',
            label: 'SIDEBAR.LABEL.CUSTOMERS',
            active: true,
            link: {
              route: 'customers.list'
            }
          }
        ],
      },
      {
        label: 'SIDEBAR.LABEL.PARAMETERS',
        icon: 'fa-cog',
        active: false,
        open: false,
        notGranted: !this.isGranted(['ROLE_WALISOFT_AGENT']),
        subMenu: [
          {
            id: 'warrantiesLink',
            label: 'SIDEBAR.LABEL.WARRANTIES',
            active: false,
            link: {
              route: 'warranty.list'
            },
          },
          {
            id: 'manufacturersLink',
            label: 'SIDEBAR.LABEL.MANUFACTURERS',
            active: false,
            link: {
              route: 'manufacturer.list'
            },
          },
          {
            id: 'taskLink',
            label: 'SIDEBAR.LABEL.TASK_MANAGER',
            active: false,
            link: {
              route: 'task-manager.list'
            },
          },
          {
            id: 'qualityExport',
            label: 'SIDEBAR.LABEL.ORDERS.QUALITY_EXPORT',
            active: false,
            link: {
              route: 'quality.export',
            },
          },
          {
            id: 'salesLogExport',
            label: 'SIDEBAR.LABEL.SALESLOG',
            active: false,
            link: {
              route: 'saleslog.export',
            },
          },
          {
            id: 'marketplacesConfigure',
            label: 'SIDEBAR.LABEL.ORDERS.MARKETPLACES_CONFIGURE',
            active: false,
            link: {
              route: 'marketplaces.commission',
            },
          },
        ]
      },
      {
        id: 'externalLightMenu',
        label: 'SIDEBAR.LABEL.ORDERS.TITLE',
        icon: this.isAdmin() ? '' : 'fa-list',
        active: false,
        open: false,
        notGranted: (!this.isGranted(['ROLE_EXTERNAL_REVERSE']) || this.isGranted('ROLE_WALISOFT_WAREHOUSE_AGENT')) && !this.isAdmin(),
        subMenu: [
          {
            id: 'orderManagerLight',
            label: 'SIDEBAR.LABEL.ORDERS.ALL',
            active: false,
            link: {
              route: 'order_manager_light.list',
            },
          },
          {
            id: 'reversesLink',
            label: 'SIDEBAR.LABEL.REVERSES',
            active: false,
            notGranted: !this.isGranted(['ROLE_EXTERNAL_REVERSE']),
            link: {
              route: 'reverses.list',
            },
          },
        ],
      }
    ];
    this.setActiveItem();
  }

  public setActiveItem(): void {
    this.toggle.forEach(item => {
      if (item.subMenu) {
        item.subMenu.forEach(subItem => {
          if (subItem.subSubMenu) {
            subItem.subSubMenu.forEach(subSubItem => {
              if (subSubItem.link && subSubItem.link.route === this.state.current.name) {
                item.active = true;
                return;
              }
            });
          }
          if (subItem.link && subItem.link.route === this.state.current.name) {
            item.active = true;
            return;
          }
        });
      }
      if (item.link && item.link.route === this.state.current.name) {
        item.active = true;
        return;
      }
    });
  }

  public isMenuOpen(): boolean {
    return SessionHelper.get('MENU_IS_OPEN');
  }

  public toggleButton(event: any, link: any, item: any, parentItem: any): void {
    if (item.subSubMenu) {
      item.open = !item.open;
      return;
    }

    if (!link) {
      return;
    }

    if (event.metaKey || event.ctrlKey) {
      window.open(this.state.href(link.route, link.params, { absolute: true }));
      return;
    }

    this.state.go(link.route, link.params, link.options);
    this.toggle.forEach((toggleItem: any) => toggleItem.active = false);
    this.toggle.find((toggleItem: any) => toggleItem === parentItem).active = true;
  }

  public getMarketplaceList(): ISidebarSubMenu[] {
    return SessionHelper.getMarketplaces()
      .filter(marketplace => 'moncornerjardin' !== marketplace.code)
      .map(marketplace => {
        return {
          id: marketplace.code,
          label: marketplace.commercialName,
          active: false,
          link: {
            route: 'order.list',
            params: { 'marketplaces[]': [marketplace.code] },
          }
        };
      });
  }

  public openList(item: any, open: boolean): void {
    this.toggle.forEach((toggleItem: any) => toggleItem.open = false);
    this.toggle.find((toggleItem: any) => toggleItem === item).open = open;

    setTimeout((): void => { this.setSubMenuPosition(); }, 100);
  }

  private setSubMenuPosition(): void {
    const subList: HTMLElement = <HTMLElement>document.getElementsByClassName('sidebar__sub-list')[0];

    if (subList) {
      const rect = subList.getBoundingClientRect();

      if (rect.bottom > document.documentElement.clientHeight) {
        subList.style.bottom = '5px';
      }

      subList.style.display = 'inline-block';
    }
  }

  public hasShowroomMarketplace(): boolean {
    return !!this.marketplaces.find(marketplace => 'showroom' === marketplace.code);
  }
}
