import { SessionHelper, UrlHelper } from '@helpers';
import { LOCALE_FR } from './country';

export const WEBSITE_URLS: any = {
  fr_FR: 'https://www.sweeek.fr',
  es_ES: 'https://www.sweeek.es',
  en_GB: 'https://www.sweeek.co.uk',
  fr_BE: 'https://fr.sweeek.be',
  nl_BE: 'https://nl.sweeek.be',
  it_IT: 'https://www.sweeek.it',
  nl_NL: 'https://www.sweeek.nl',
  pt_PT: 'https://www.sweeek.pt',
};


export function getWebsiteHostFromEnv() {
  return getWebsiteHostFromEnvForLocale(SessionHelper.getCountry().defaultLocale);
}

export function getWebsiteHostFromEnvForLocale(locale: string) {
  locale = Object.keys(WEBSITE_URLS).indexOf(locale) !== - 1 ? locale : LOCALE_FR;
  const currentHostname = (new URL((new UrlHelper()).getBaseUrl())).hostname;
  let websiteBaseUrl = WEBSITE_URLS[locale];
  switch (currentHostname) {
    case 'localhost.bo.sweeek.guru':
      websiteBaseUrl = websiteBaseUrl.replace(/https:\/\/((www\.)|((\w+\.)))(sweeek(\.(\w+))+)/, `https://localhost.$4$5`);
      break;
    case 'preprod-bo.sweeek.guru':
      websiteBaseUrl = websiteBaseUrl.replace(/https:\/\/((www)|(\w+))(.*)/, `https://preprod-$3$4`).replace('preprod-.', 'preprod.');
      break;
  }

  return websiteBaseUrl;
}
