import { Component, Inject, OnInit } from '@angular/core';
import { SessionHelper, CountryHelper } from '@helpers';
import { CountryResource, AttributeResource } from '@resources';
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';
import { ICountry } from '@interfaces';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-attribute-table',
  template: require('./attribute-table.component.html')
})
export class AttributeTableComponent extends AbstractPageComponent implements OnInit {

  public locales: any;
  public isListFrozen: boolean;
  public attributes: any;
  public attributesSets: any;
  public attributesSetsAttributes: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private countryResource: CountryResource,
    private attributesSetResource: AttributeSetResource,
    private attributeResource: AttributeResource,
  ) {
    super($translate, authService, null, state);
  }

  public ngOnInit(): void {
    this.fetchAttributes();
    this.fetchAttributeSets();
    this.fetchLinkedAttributes();
    this.fetchCountries();
    this.isListFrozen = true;
  }

  private fetchAttributes(): void {
    this.attributeResource.getMany()
      .takeUntil(this.destroyed$)
      .subscribe(attributesResponse => this.attributes = attributesResponse)
    ;
  }

  private fetchAttributeSets(): void {
    this.attributesSetResource.getMany()
      .takeUntil(this.destroyed$)
      .subscribe(response => this.attributesSets = response)
    ;
  }

  private fetchLinkedAttributes(): void {
    this.attributesSetResource.getLinkedAttributes()
      .takeUntil(this.destroyed$)
      .subscribe(response => this.attributesSetsAttributes = response)
    ;
  }

  private fetchCountries(): void {
    this.countryResource.getMany(
      { active: true, locales: [SessionHelper.getLocale()] },
      { entryPoint: '/v2/countries', dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((countriesResponse: ICountry[]) => this.locales = CountryHelper.getLocales(countriesResponse))
    ;
  }
}
