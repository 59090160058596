export default automaticDiscountRoutes;

/** @ngInject */
function automaticDiscountRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'website',
    sidebarSubNav: 'automatic-discount',
    title: '',
    reloadChangeCountry: 'automatic-discount.list',
  };

  $stateProvider
    .state('automatic-discount', {
      url: '/automatic/discount',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.DISCOUNT_AUTOMATIC.GENERAL',
        stateName: 'automatic-discount.list'
      }
    })
    .state('automatic-discount.list', {
      url: `?
        discount.name
        &type
        &page
        &byPage
      `,
      views: {
        'page@layout': {
          template: '<app-automatic-discount-list></app-automatic-discount-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT_AUTOMATIC.LIST.TITLE',
      })
    })
    .state('automatic-discount.new', {
      url: '/new',
      params: {
        type: null
      },
      breadcrumb: { label: 'BREADCRUMB.NEW', stateName: 'automatic-discount.new' },
      views: {
        'page@layout': { template: '<app-automatic-discount-form></app-automatic-discount-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT_AUTOMATIC.NEW.TITLE',
        reloadChangeCountry: 'automatic-discount.new',
        formTab: 'general',
      })
    })
    .state('automatic-discount.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'automatic-discount.edit' },
      views: {
        'page@layout': { template: '<app-automatic-discount-form></app-automatic-discount-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT_AUTOMATIC.EDIT.TITLE',
        reloadChangeCountry: 'automatic-discount.edit',
        reloadChangeCountrySearchParam: 'id',
        formTab: 'general'
      })
    })
  ;
}
