import { Component, Inject, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { IDiscountFormGeneralModel } from '@components/discount/interfaces/discount-form.interface';
import { DISCOUNT_TYPES } from '@components/discount/list/services/discount-filters-form.service';
import { CODE_ES, CODE_GB, CODE_IT, CODE_PT } from '@constants';
import { SessionHelper } from '@helpers';
import { EDITION_PAGE, ICountry } from '@interfaces';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import {getTranslatableFormData, isHighlightable} from '@components/discount/form/general/discount-form-utils';

interface Highlight {
  [key: string]: string | undefined;
}

export const HIGHLIGHT: Highlight = {
  ES: 'Utilice el código <b>{{ code }}</b>. Obten un -{{ amount }} dto. en este producto',
  IT: 'Utilizza il codice <b>{{ code }}</b> per ottenere il {{ amount }} di sconto su questo prodotto',
  PT: 'Utilize o código <b>{{ code }}</b> para obter {{ amount }} de desconto neste produto',
  GB: 'Use code <b>{{ code }}</b> to get {{ amount }} off this product'
};

/**
 * Handle translations form.
 */
@Component({
  selector: 'app-discount-form-translations',
  template: require('./discount-form-translations.html'),
})
export class DiscountFormTranslationsComponent extends AbstractComponent {

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  @Input() isReadOnly: boolean = false;
  @Input() discount: IDiscountFormGeneralModel;
  @Input() parentForm: FormGroup;
  @Input() pageType: string;

  protected currentCountry: ICountry = SessionHelper.getCountry();
  public readonly currency = SessionHelper.getCurrency().toLowerCase();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public updateCode(code: string): void {
    if (
      isHighlightable(this.parentForm, this.currentCountry, this.pageType, DISCOUNT_TYPES.product)
    ) {
      const { amount, type } = getTranslatableFormData(this.parentForm, this.currency);

      this.translationsFA.at(this.tabIndex).get('title').setValue(
        (HIGHLIGHT[this.currentCountry.code]).replace('{{ code }}', code).replace('{{ amount }}', amount + type)
      );
    }
  }
}
