import {Injectable} from '@angular/core';
import {Resource} from '@decorators';
import {AbstractResource} from '@resources';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {IImport} from '@components/generic/import-v2/models/import-field.model';

@Injectable()
@Resource({
  entryPoint: '/import',
  translationKey: 'IMPORT',
  isHydra: true,
})
export class ImportResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public submitImportValidation(data: IImport): Observable<any> {
    return this.update(data.process.processId, data, {
      entryPoint: `${this.entryPoint}/submit`,
    });
  }
}
