import { Component, Inject, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';

interface ISelectableCountry {
  id: number;
  code: string;
  label: string;
}

@Component({
  selector: 'app-duplicate-product-new-content',
  template: require('./duplicate-product-new-content.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class DuplicateProductNewContentComponent extends AbstractFormComponent implements OnInit {

  public form: FormGroup;
  public countries: ISelectableCountry[];
  public currentCountry: ICountry;
  public open: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.currentCountry = SessionHelper.getCountry();
    this.countries = SessionHelper.getCountries().map((country: ICountry) => {
      return {
        id: country.id,
        code: country.code,
        label: country.translations[this.currentLocale].name,
      };
    });
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      country: [{
        label: this.currentCountry.translations[this.currentLocale].name,
        id: this.currentCountry.id,
        code: this.currentCountry.code,
      }],
      sku: [''],
      openAiTranslation: [false]
    });
  }

  public closeModal(): void {
    this.open = false;
  }

  public openModal(): void {
    this.open = true;
    this.createForm();
  }

  public duplicateProduct(): void {
    this.resource.duplicateNewContent(+this.state.params.id, +this.form.get('sku').value.id, !!this.form.get('openAiTranslation').value, { dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.open = false;
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
        this.state.go(`${this.resource.routeName}.edit`, {id: this.state.params.id}, {reload: true});
      });
  }

  public resetSku(): void {
    this.form.get('sku').reset();
  }
}
