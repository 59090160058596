import { FormGroup, FormArray } from '@angular/forms';
import { CODE_ES, CODE_GB, CODE_IT, CODE_PT } from '@constants';
import { ICountry } from '@interfaces';

export function getTranslatableFormData(form: FormGroup, currency: string): { amount: string, type: string } {
  const amountForm = form.get('amount').value;
  const percentageForm = form.get('percentage').value;

  const amount = !isNaN(amountForm) && amountForm !== null ? amountForm :
    !isNaN(percentageForm) && percentageForm !== null ? percentageForm : '';

  const type = !isNaN(amountForm) && amountForm !== null ? currency :
    !isNaN(percentageForm) && percentageForm !== null ? '%' : '';

  return { amount, type };
}

export function isHighlightable(form: FormGroup, currentCountry: ICountry, pageType: string, discountType: string): boolean {
  return form.get('type').value === discountType
    && [CODE_ES, CODE_PT, CODE_IT, CODE_GB].includes(currentCountry.code)
    && form.get('highlight').value === true
    && pageType !== 'EDITION_PAGE';
}
