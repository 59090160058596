import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Moment } from 'moment';
import { SessionHelper } from '@helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IWarehouses } from '@components/warehouses/models';
import { AbstractResource } from '@resources';
import { CarrierScaleResource } from '@resources/carrier-scale.resource';
import { DATE_FULL_FORMAT } from '@constants';

@Component({
  selector: 'app-carrier-capacity',
  template: require('./carrier-capacity.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class CarrierCapacityComponent extends AbstractPageComponent implements OnInit {
  public year: number = +moment().format('YYYY');
  public week: number = +moment().format('W');
  public warehouse: any;
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public warehouseForm: FormGroup;
  public days: Moment[] = [];
  public scaleDays: Moment[] = [];
  public capacities: {[key: string]: any[]};
  public scheduledScales: {[key: string]: any[]};
  public autoScales: {[key: string]: any[]};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private carrierCapacityResource: CarrierCapacityResource,
    private carrierScaleResource: CarrierScaleResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.setDays();
    this.buildWarehouseForm();
    this.setWarehouse('lsl');
  }

  public warehouseChange(): void {
    this.warehouse = this.warehouses.find((item: IWarehouses): boolean => {
      return item['@id'] === this.warehouseForm.value.warehouse;
    });

    this.fetchCapacities();
    this.fetchScheduledScales();
    this.fetchAutoScales();
  }

  public buildWarehouseForm(): void {
    this.warehouseForm = this.formBuilder.group({
      warehouse: [null],
    });
  }

  public setWarehouse(code: string): void {
    this.warehouseForm.get('warehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === code;
    })['@id']);

    this.warehouseChange();
  }

  public setDays(): void {
    const firstDay: Moment = moment().day('Monday').year(this.year).week(this.week);
    this.days = [firstDay];
    this.scaleDays = [firstDay];

    for (let i: number = 1; i < 5; ++i) {
      this.days.push(firstDay.clone().add(i, 'days'));
    }

    for (let i: number = 1; i < 7; ++i) {
      this.scaleDays.push(firstDay.clone().add(i, 'days'));
    }
  }

  public fetchCapacities(): void {
    if (!this.warehouse) {
      return;
    }

    this.capacities = null;

    const body: any = {
      'warehouseCarriers.warehouse.code': this.warehouse.code,
      year: this.year,
      week: this.week
    };

    this.carrierCapacityResource.cGet(body, { isHydra: true, returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        const capacities: any = [];

        const capacitiesWithDay: any[] = response.filter((item): boolean => {
          return null !== item.day;
        });

        this.days.forEach((day: Moment): void => {
          capacities[day.format('YYYY-MM-DD')] = capacitiesWithDay.filter((item: any): boolean => {
            return item.dayOfWeek === +day.isoWeekday() - 1;
          });
        });

        this.capacities = capacities;
      })
    ;
  }

  public fetchScheduledScales(): void {
    if (!this.warehouse) {
      return;
    }

    const body: any = {
      'warehouse.code': this.warehouse.code,
      'scheduledAt[year]': this.year,
      'scheduledAt[week]': this.week
    };

    this.carrierScaleResource.cGet(body, { isHydra: true, returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        const scales: {[key: string]: any[]} = {};

        this.scaleDays.forEach((day: Moment): void => {
          scales[day.format('YYYY-MM-DD')] = response.filter((item: any): boolean => {
            return moment(item.scheduledAt).format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
          });
        });

        this.scheduledScales = scales;
      })
    ;
  }

  public fetchAutoScales(): void {
    if (!this.warehouse) {
      return;
    }

    this.autoScales = {};
    const options: any = {
      entryPoint: '/v2/order_item_packages/capacity_scales',
      isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
      blocking: false
    };

    this.scaleDays.forEach((day: Moment): void => {
      this.carrierScaleResource.cGet({ warehouse: this.warehouse.code, date: day.format(DATE_FULL_FORMAT) }, options)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any[]): void => {
          this.autoScales[day.format('YYYY-MM-DD')] = response;
        })
      ;
    });
  }

  public goToCreation(): void {
    this.state.go('carrier.capacity.new');
  }

  public previousWeek(): void {
    --this.week;

    if (this.week < 1) {
      this.week = 53;
      --this.year;
    }

    this.setDays();
    this.fetchCapacities();
    this.fetchScheduledScales();
    this.fetchAutoScales();
  }

  public nextWeek(): void {
    ++this.week;

    if (this.week > 53) {
      this.week = 1;
      ++this.year;
    }

    this.setDays();
    this.fetchCapacities();
    this.fetchScheduledScales();
    this.fetchAutoScales();
  }
}
