import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AttributesSetModel } from '@components/attribute-set/models/attribute-set.model';
import { AttributesSetAttributeModel } from '@components/attribute-set/models/attribute-set-attribute.model';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'attribute-sets',
  entryPoint: '/attribute_sets',
  translationKey: 'ATTRIBUTE_SETS',
  model: AttributesSetModel,
  listColumns: [
    {field: 'name', translationKey: 'NAME'},
  ]
})
export class AttributeSetResource extends AbstractResource {

  protected nullableProperties: string[] = ['type'];
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Gets linked attributes.
   */
  public getLinkedAttributes(): Observable<Object> {
    return this.getMany(undefined, {
      entryPoint: `${this.entryPoint}/attributes`,
      model: AttributesSetAttributeModel
    });
  }

  /**
   * Gets linked attribute filterables.
   */
  public getLinkedAttributesFilterables(): Observable<Object> {
    return this.getMany(undefined, {
      entryPoint: `${this.entryPoint}/attribute_filterables`,
      model: AttributesSetModel
    });
  }
}
