import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { TextField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';

interface IDropdownData {
  label: string;
  value: string;
}

export const DISCOUNT_TYPES = {
  discount_minimum_products: 'discount_minimum_products',
  discount_second_product: 'discount_second_product',
  free_product: 'free_product',
};

@Injectable()
export class AutomaticDiscountFiltersFormService extends AbstractFiltersFieldsService {
  private typeList: IDropdownData[] = [
    { label: '', value: null },
    { label: this.translate.instant('PAGE.DISCOUNT_AUTOMATIC.FILTER.TYPE.DATA.MINIMUM_PRODUCTS'), value: DISCOUNT_TYPES.discount_minimum_products },
    { label: this.translate.instant('PAGE.DISCOUNT_AUTOMATIC.FILTER.TYPE.DATA.SECOND_PRODUCT'), value: DISCOUNT_TYPES.discount_second_product },
    { label: this.translate.instant('PAGE.DISCOUNT_AUTOMATIC.FILTER.TYPE.DATA.FREE_PRODUCT'), value: DISCOUNT_TYPES.free_product }
  ];
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
 ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[]  {
    return [
      new TextField({
        fieldName: 'discount.name',
        label: 'PAGE.DISCOUNT.LIST.TABLE.COLUMN.NAME',
        value: this.filters.get('discount.name') ? this.filters.get('discount.name') : undefined,
      }),

      new DropDownListField({
        fieldName: 'type',
        label: 'PAGE.DISCOUNT.FILTER.TYPE.LABEL',
        data: this.typeList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('type') ? this.filters.get('type') : undefined,
        valuePrimitive: true,
      })
    ];
  }
}
