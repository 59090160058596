export default savRoutes;

/** @ngInject */
function savRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'sav',
    title: '',
    reloadChangeCountry: 'spare-part.list',
  };

  $stateProvider
    .state('sav', {
      url: '/sav',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SPARE_PARTS',
        stateName: 'spare-part.list'
      },
    })
    .state('sav.orders_list', {
      url: `/orders?
      page
      &byPage
      &search
      &orderId[]
      &countries[]
      &warehouses[]
      &carriers[]
      &orderManagerStatuses[]
      &waveIds[]`,
      views: {
        'page@layout': {
          template: '<app-sav-orders-list></app-sav-orders-list>'
        }
      },
      breadcrumb: {
        label: 'BREADCRUMB.SAV_ORDERS',
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ORDERS_LIST.TITLE',
        showCountries: false
      })
    })
    .state('sav.order_detail', {
      url: '/orders/:id/details?fromOrderId',
      views: {
        'page@layout': {
          template: '<app-sav-order-detail></app-sav-order-detail>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ORDER.DETAIL.TITLE',
        showCountries: false
      })
    })
    .state('sav.arrival_list', {
      url: '/arrivals?startDate&endDate&reasons&type&sparePartId&parentSku&childSku[]',
      views: {
        'page@layout': {
          template: '<app-sav-arrival-list></app-sav-arrival-list>'
        }
      },
      breadcrumb: {
        label: 'BREADCRUMB.SPARE_PARTS_ARRIVALS',
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ARRIVAL.LIST.TITLE',
        showCountries: true
      })
    })
    .state('sav.arrival_new', {
      url: '/arrivals/new',
      views: {
        'page@layout': {
          template: '<app-sav-arrival-form></app-sav-arrival-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ARRIVAL.NEW.TITLE',
        showCountries: true
      })
    })
    .state('sav.arrival_edit', {
      url: '/arrivals/:id/edit',
      views: {
        'page@layout': {
          template: '<app-sav-arrival-form></app-sav-arrival-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ARRIVAL.EDIT.TITLE',
        showCountries: true
      })
    })
    .state('sav.stock_new', {
      url: '/stocks/new?sparePartId&masterProductId&label',
      views: {
        'page@layout': {
          template: '<app-sav-arrival-form [stock-movement]="true"></app-sav-arrival-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ARRIVAL.NEW.TITLE',
        showCountries: true
      })
    })
    .state('sav.order_new', {
      url: '/orders/new/:id',
      views: {
        'page@layout': {
          template: '<app-sav-order-new></app-sav-order-new>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SAV.ARRIVAL.NEW.TITLE',
        showCountries: true
      })
    })
  ;
}
