import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionHelper } from '@helpers';
import { AuthService, IJwt } from '@services';
import { ICredentials, AuthResource } from '@components/login/resources/auth.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { DASHBOARD_REVENUES } from '@constants/route.constants';
import { UserResource } from '@resources';
import { IUser } from '@models';
import { ICarrierGroup, ICountry, IMarketplace, IMarketplacesByCountry } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { ITaskStatus } from '@components/generic/task-manager/interfaces';

@Component({
  selector: 'app-oauth-validate',
  template: require('./oauth-validate.component.html'),
})
export class OauthValidateComponent extends AbstractComponent implements OnInit {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private loginCheckResource: AuthResource,
  ) {
    super($translate, authService, null, state);

    SessionHelper.clearStorage();
  }

  public ngOnInit() {
    // @ts-ignore
    const {code, scope, platform} = this.state.params;

    this.state.go('anonymous.sign-in', {code, scope, platform});
  }
}
